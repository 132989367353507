import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArticleCard from "../components/ArticleCard";
import ArticleSlider from "../components/ArticleSlider";
import Testimonial from "../components/Testimonial";
import { toast } from "react-toastify";
import { getAllArticleApi } from "../network/api";
import { HASHTAG } from "../utils/enums";
import Modal from "../components/Modal";
import Pagination from "../components/Pagination";

const HomePage = () => {
  const [article, setArticle] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  // Get title query parameter from URL
  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return params.get("search") || "";
  };

  const fetchArticleDetails = async () => {
    try {
      const title = getQueryParams(); // Retrieve title from URL
      console.log("Search title:", title);
      const response = await getAllArticleApi(page, HASHTAG.STARTUP_HUB, title);
      if (response.status === 200 || response.data.status === 200) {
        setArticle(response.data.data);
        setTotal(response.data.totalPages);
      } else {
        toast.error("Failed to fetch articles.");
      }
    } catch (e) {
      console.log("Fetch Error:", e);
      toast.error(e.message);
    }
  };

  useEffect(() => {
    fetchArticleDetails();
  }, [page, location.search]); // Re-run on page or query change

  // Update the URL with search query and reset to page 1
  const handleSearch = (searchQuery) => {
    const trimmedQuery = searchQuery.trim();
    navigate({
      pathname: location.pathname,
      search: trimmedQuery ? `?title=${trimmedQuery}` : "",
    });
    setPage(1); // Reset to page 1 on new search
  };

  return (
    <>
      <Modal />
      <div className="container-fluid mt-1 mb-3">
        <ArticleSlider />
        <h3 className="mb-4">Latest StartUp Coverage</h3>
        <div className="row">
          {article.length > 0 ? (
            article.map((data, index) => (
              <div className="col-sm-6 mb-4" key={index}>
                <ArticleCard key={index} data={data} />
              </div>
            ))
          ) : (
            <div className="col-12 d-flex align-items-center justify-content-center" style={{ minHeight: '200px' }}>
            <p className="text-center"><i>No results to show</i></p>
          </div>
          
          )}
        </div>

        {article.length > 0 && (
          <Pagination total={total} page={page} setPage={setPage} />
        )}

        <div className="mt-4">
          <Testimonial />
        </div>
      </div>
    </>
  );
};

export default HomePage;