import React from "react";

const TestimonialCard = ({ data, isCenter }) => {
  return (

    <div className="d-flex flex-column h-100 bg-light border p-4" style={{borderRadius:5}}>
        <div className="d-flex justify-content-center">
          <img
              src={data.image}
              alt={data.by}
              className="rounded-circle mb-3"
              style={{
                objectFit: "cover",
                border: "5px solid #fff",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
              }}
            />
          </div>
          <div className="mb-2" style={{overflow:'hidden',flex:1, textAlign:'center'}}>
            <i className="bx bxs-quote-alt-left fs-5"></i>{data.text}<i className="bx bxs-quote-alt-right fs-5"></i>
          </div>
          <div className="mb-3" align="center"><i>&mdash; {data.by}</i></div>
    </div>

      // <div
      //   className="card text-center shadow-sm testimonial-card"
      //   style={{
      //     borderRadius: "10px",
      //     overflow: "hidden",
      //   }}
      // >
      //   <div className="card-body p-4">
          
      //     <p className="card-text text-muted" style={{height:100,overflow:'hidden'}}>
      //       <q>{data.text}</q>
      //     </p>
      //     <h5 className="card-title mt-3">{data.by}</h5>
      //     <p className="card-subtitle text-muted">{data.role}</p>
      //   </div>
      // </div>

  );
};

export default TestimonialCard;
