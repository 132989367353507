import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { googleLogin, loginStudent } from "../../redux/slices/authSlice";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import EditorialBoardView from "./EditorialBoardView";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";

const StudentLoginPage = () => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      dispatch(loginStudent(data));
    } catch (error) {
      console.error("Login error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  function handelGoogleLogin(response) {
    if (response?.credential) {
      setIsLoading(true);
      try {
        dispatch(googleLogin(response.credential));
      } catch (error) {
        console.error("Login error:", error);
      } finally {
        setIsLoading(false);
      }
    }
    console.log("login data:", response);
  }
  const CLIENT_ID =
    "752857055606-joincugu9tgrkuankmchntj802bgdjlq.apps.googleusercontent.com";
  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <div className="container-fluid border rounded">
        <h1 className="h3 mb-4 mt-3 fw-normal text-center">Student Login</h1>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-floating mb-3">
            <input
              type="email"
              className="form-control"
              id="floatingInput"
              placeholder="name@example.com"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: "Invalid email address",
                },
              })}
            />
            <label htmlFor="floatingInput">Email address</label>
            {errors.email && (
              <small className="error-text">{errors.email.message}</small>
            )}
          </div>
          <div className="form-floating mb-3 position-relative">
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              id="floatingPassword"
              placeholder="Password"
              {...register("password", {
                required: "Password is required",
                minLength: {
                  value: 4,
                  message: "Password must be at least 6 characters long",
                },
              })}
            />
            <label htmlFor="floatingPassword">Password</label>

            <button
              type="button"
              className="btn btn-outline-secondary position-absolute end-0 top-50 translate-middle-y"
              onClick={togglePasswordVisibility}
              style={{ zIndex: 2, marginRight: "0.75rem" }}
            >
              <i className={`bx ${showPassword ? "bx-hide" : "bx-show"}`}></i>
            </button>
          </div>
          {errors.password && (
            <small className="error-text">{errors.password.message}</small>
          )}

          <button
            className="btn btn-primary w-100 py-2 mb-2"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? "Signing in..." : "Sign in"}
          </button>

          <div className="text-center">
            <GoogleLogin
              onSuccess={handelGoogleLogin}
              onError={(err) => {
                console.log("login errors", err);
                // toast.error(err)
              }}
              useOneTap={false} // Optional: enables Google One Tap
            />
          </div>

          <div className="d-flex justify-content-between mb-3">
            <Link to={"register"}>Create an account</Link>
            <Link to={"forgot-password"}>Forgot Password?</Link>
          </div>
        </form>
        <div className="p-2">
          <EditorialBoardView />
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default StudentLoginPage;
