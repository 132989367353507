const EditorProfileItem = ({ name, title, email, university, location, image, column }) => {
  return (
    <div
      className={
        "editor-profile-item d-flex " + (column ? "flex-column" : "flex-row")
      }
    >
      <div>
        <img
          className="rounded"
          src={image}
          alt={`${name}'s profile`}
          style={{ width: column ? 60 : "100%", maxHeight: 80 }}
        />
      </div>
      <div className="d-flex">
        <p className={column ? "py-2" : "ps-3 pe-2"}>
          {name},<br />
          {title} <br />
          {email} <br />
          {university},<br />
          {location}
        </p>
      </div>
    </div>
  );
};

export default EditorProfileItem;
