import React from "react";
import moment from 'moment';
import { useNavigate,} from 'react-router-dom';
const ArticleCard = ({ data }) => {
  const navigate = useNavigate();
  const handleArticle= async(id)=>{
    navigate(`/article/${id}`);
  }
  return (
    <div className="col-12 article-card cursor-pointer"  onClick={()=>handleArticle(data._id)}>
      <div className="card">
        <img src={data.image} className="card-img-top" alt="Card Image" />
        <div className="card-body d-flex flex-column">
          <h5 className="card-title text-start p-0" style={{maxHeight:50,overflow:'hidden'}}>{data.title}</h5>
          <p className="text-brief-safe">{data.brief}</p>
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center fs-6 fw-light">
              {data.author?.name && (
                <>
                <i className="bx bxs-user-circle fs-5 text-muted"></i>
                <span className="ms-1">{data.author.name}</span>
                </>
              )}
              {" "}
            </div>
            <div className="d-flex align-items-center fs-6 fw-light">
              <i className="bx bx-calendar fs-5 text-muted"></i>
              <span className="ms-1">{moment(data.postDate).format("MMM Do YY")}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleCard;
 