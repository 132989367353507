import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getPageDataApi } from "../network/api";
import Testimonial from "../components/Testimonial";

const AboutUs = () => {
  const [data, setData] = useState("");
  const fetchAboutUs = async () => {
    try {
      let response = await getPageDataApi("about_us");
      if (response.status === 200 || response.data.status === 200) {
        setData(response.data.data.about_us); 
      }
    } catch (e) {
      console.error("Fetch Error:", e);
      toast.error("Failed to load About Us content");
    }
  };

  useEffect(() => {
    fetchAboutUs();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row justify-content-center card mx-auto shadow">
        <div className="card-body p-lg-5 p-3">
          
          <div
            className="content"
            style={{ textAlign: "justify" }}
            dangerouslySetInnerHTML={{ __html: data }}
          ></div>
        </div>
      </div>
      <div className="mt-5">
        <Testimonial />
      </div>
    </div>
  );
};

export default AboutUs;
