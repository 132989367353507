export const HASHTAG = {
  STARTUP_HUB: "startup",
  LEGAL_REPORT: "legal-report",
  CURIOSITY: "curiosity",
  STUDENT_CORNER: "student-corner",
  JOBS_INTERNSHIP: "jobs-internship",
  RESERACH_PAPER: "research-papers",
  YOUR_STORY:"your-story",
};
export const PageRoutes = {
  STARTUP_HUB: "/",
  LEGAL_REPORT: "/legal-reports",
  CURIOSITY: "/curiosity",
  STUDENT_CORNER: "/student-corner",
  JOBS_INTERNSHIP: "/jobs-and-internships",
  RESERACH_PAPER: "/research-papers",
  YOUR_STORY:"/your-story",
}
export const HashtagMap = {
 [PageRoutes.STARTUP_HUB]:HASHTAG.STARTUP_HUB,
 [PageRoutes.LEGAL_REPORT]:HASHTAG.LEGAL_REPORT,
 [PageRoutes.CURIOSITY]:HASHTAG.CURIOSITY,
 [PageRoutes.STUDENT_CORNER]:HASHTAG.STUDENT_CORNER,
 [PageRoutes.JOBS_INTERNSHIP]:HASHTAG.JOBS_INTERNSHIP,
 [PageRoutes.RESERACH_PAPER]:HASHTAG.RESERACH_PAPER,
 [PageRoutes.YOUR_STORY]:HASHTAG.YOUR_STORY,
}

export const ArticleStatus = {
  PENDING:'pending',
  APPROVED:'approved',
  REJECTED:'rejected',
}
export const UserHashtag=[
  {
    label:"Technology", 
    key:"technology "
  },
  {
    label:"Media", 
    key:"media"
  },
  {
    label:"Education", 
    key:"education"
  },
  {
    label:"Business", 
    key:"business "
  },
  {
    label:"Art & Culture", 
    key:"art-culture"
  },
  {
    label:"Sports", 
    key:"sports"
  },
  {
    label:"Policy", 
    key:"policy "
  },
  {
    label:"Management", 
    key:"management "
  },
  {
    label:"Environment", 
    key:"environment "
  },
  {
    label:"Humanities", 
    key:"humanities "
  },
  {
    label:"Wellness", 
    key:"wellness "
  }
];


