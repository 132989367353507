import React from "react";

function Show404() {
  return (
    <div className="text-center">
      <h1>404</h1>
      Page Not Found
    </div>
  );
}

export default Show404;
