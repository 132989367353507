import { useEffect, useState } from "react";
import ArticleCard from "../components/ArticleCard";
// import ArticleSlider from "../components/ArticleSlider";
// import Carousel from "../components/Carousel";
import Testimonial from "../components/Testimonial";
import { toast } from "react-toastify";
import { getAllArticleApi } from "../network/api";
import { HASHTAG, UserHashtag } from "../utils/enums";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Pagination from "../components/Pagination";
import Modal from "../components/Modal";

const CuriosityPage = () => {
  const navigate = useNavigate();
  const { hashtag } = useParams();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [article, setArticle] = useState([]);
  const [selectedHashtag, setSelectedHashtag] = useState(hashtag);
  const location = useLocation();

  const handleArticle = async (id) => {
    navigate(`/article/${id}`);
  };

  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return params.get("search") || "";
  };

  const handleCuriosity = async (item) => {
    setSelectedHashtag(item.key); // Update selected hashtag

    setPage (1)
    navigate(`/curiosity/${item.key}`);
  };

  const fetchArticleDetails = async () => {
    try {
      const title = getQueryParams();
      let response = await getAllArticleApi(page, hashtag ?? HASHTAG.CURIOSITY,title);
      if (response.status === 200 || response.data.status === 200) {
        setArticle(response.data.data);
      }
      setTotal(response.data.totalPages);
    } catch (e) {
      console.log("Fetch Error:", e);
      toast.error(e.message);
    }
  };

  useEffect(() => {
    fetchArticleDetails();
  }, [hashtag, page,location.search]);

  return (
    <>
    <Modal />
      <div className="container mt-1 mb-3">
        <h3 className="mb-4">Trends Pick Ups</h3>

        <div className="d-flex flex-wrap mb-4">
          {UserHashtag.map((item, index) => (
            <button
              key={index}
              type="button"
              className={`btn m-1 ${
                selectedHashtag === item.key ? "btn-dark" : "btn-outline-dark" 
              }`}
              onClick={() => handleCuriosity(item)}
            >
              {item.label}
            </button>
          ))}
        </div>
        
        <div className="">
        <div className="row">
          {article.length > 0 ? (
            article.map((data, index) => (
              <div
                className="col-sm-6 mb-4"
                key={index}
                onClick={() => handleArticle(data._id)}
              >
                <ArticleCard data={data} />
              </div>
            ))
          ) : (
            <div className="col-12 d-flex align-items-center justify-content-center" style={{ minHeight: '200px' }}>
            <p className="text-center"><i>No results to show</i></p>
          </div>
          )}
        </div>
        </div>

        {article.length > 0 && (
          <Pagination total={total} page={page} setPage={setPage} />
        )}

        <div className="mt-4">
          <Testimonial />
        </div>
      </div>
    </>
  );
};

export default CuriosityPage;