import React, { useEffect, useState } from "react";
import { userArticleViewApi, downloadCertificatePdfByUserApi } from "../../network/api"; 
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { ArticleStatus } from "../../utils/enums";

const StudentArticlePage = ({ data }) => {
  const navigate = useNavigate();
  const [userArticle, setUserArticle] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleArticle = async (article) => {
    if (article) {
      navigate(`/user-article`, { state: { article } });
    } else {
      console.error("Article is undefined");
    }
  };

  const handleEditArticle = async (article) => {
    if (article) {
      navigate(`/edit-article`, { state: { article } });
    } else {
      console.error("Article is undefined");
    }
  };

  const handleDownloadPdf = async (article) => {
    const { _id, articleStatus } = article; // Extract ID and other needed properties
    const type = articleStatus === ArticleStatus.APPROVED ? "appreciationCertificate" : "appreciation"; // Define type based on your logic

    try {
      const response = await downloadCertificatePdfByUserApi("appreciation", _id); // Pass type and id to the API
      if (response.status === 200 && response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data])); // Create a URL for the blob
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `appreciation_certificate_${_id}.pdf`); // Set the file name
        document.body.appendChild(link);
        link.click(); // Trigger download
        document.body.removeChild(link); // Clean up
        toast.success("PDF downloaded successfully!");
      } else {
        toast.error("Failed to download PDF");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || error.message); // Handle errors
    }
  };

  const fetchUserArticleView = async () => {
    setLoading(true);
    try {
      let response = await userArticleViewApi();
      if (response.status === 200 && response.data.status === 200) {
        setUserArticle(response.data.data);
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserArticleView();
  }, []);

  return (
    <>
      <div className="row">
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "50vh" }}
          >
            <div className="spinner-border text-dark" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : userArticle.length === 0 ? (
          <div className="col-12">
            <p className="text-center">No articles available.</p>
          </div>
        ) : (
          userArticle.map((item, index) => (
            <div className="col-12" key={index}>
              <div className="card mb-3">
                <div className="card-body">
                  <h4 className="text-start">{item.title}</h4>
                  <p className="text-start">{item.brief}</p>

                  <div className="text-start">
                    <div className="row">
                      <div className="col-sm-8">
                        <div className="d-flex  align-items-start ">
                          <ArticleStatusView status={item.articleStatus} />

                          <div className="d-flex flex-row align-items-center gap-2">
                            <div className="flex-grow-1">
                              <button
                                type="button"
                                className="btn btn-primary w-100 mb-2"
                                onClick={() => handleArticle(item)}
                              >
                                <i className="bx bx-show"></i> View
                              </button>
                            </div>

                            {item.articleStatus === ArticleStatus.REJECTED && (
                              <div className="flex-grow-1">
                                <button
                                  type="button"
                                  className="btn btn-warning w-100 mb-2"
                                  onClick={() => handleEditArticle(item)}
                                >
                                  <i className="bx bx-edit"></i> Edit
                                </button>
                              </div>
                            )}

                            {item.articleStatus === ArticleStatus.APPROVED && (
                              <div className="flex-grow-1">
                                <button
                                  type="button"
                                  className="btn btn-success w-100 mb-2"
                                  onClick={() => handleDownloadPdf(item)} // Call the download handler
                                >
                                  <i className="bx bx-download"></i> Certificate
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="text-end fs-6 fw-light">
                          <i className="bx bx-calendar fs-5 text-muted"></i>
                          <span className="ms-1">
                            {moment(item.updatedAt).format("MMM Do YY")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {item.articleStatus === ArticleStatus.REJECTED && item.remarks && (
                   <div className="col-12 p-1 mt-2 mb-2 text-danger text-start">
        <span className="fst-italic ">Reason:</span> 
        <span className="fw-bold " dangerouslySetInnerHTML={{ __html: item.remarks }}></span>
      </div>
                  )}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
};

const ArticleStatusView = ({ status }) => {
  if (status === ArticleStatus.APPROVED)
    return <h2 className="btn btn-success me-2 mb-2">Approved</h2>;
  else if (status === ArticleStatus.REJECTED)
    return <h2 className="btn btn-danger me-2 mb-2">Rejected</h2>;
  else if (status === ArticleStatus.PENDING)
    return <h2 className="btn btn-warning me-2 mb-2">Pending</h2>;
};

export default StudentArticlePage;
