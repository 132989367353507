import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import React, { useState } from "react";

import { Pagination, Navigation, Autoplay } from "swiper/modules";
import ArticleCard from "./ArticleCard";
import { getHighlighArticleApi } from "../network/api";
import { toast } from "react-toastify";

import { useEffect } from "react";
import { HASHTAG, HashtagMap } from "../utils/enums";
import { useLocation } from "react-router-dom";

const ArticleSlider = () => {
  const location = useLocation();
  const [article, setArticle] = useState([]);

  const fetchArticleDetails = async () => {
    try {
      let hashtag = HashtagMap[location.pathname];
      let response = await getHighlighArticleApi(hashtag);
      if (response.status === 200 || response.data.status === 200) {
        setArticle(response.data.data);
      }
    } catch (e) {
      toast.error(e.message);
    }
  };
  useEffect(() => {
    fetchArticleDetails();
  }, [location.pathname]);

  return (
    <div className="row">
      <div className="col-lg-12 mb-4">
        <Swiper
          pagination={{
            clickable: true,
          }}
          autoplay={{ delay: 3000, disableOnInteraction: true }}
          modules={[Pagination, Navigation, Autoplay]}
          className="swiper"
          navigation
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            576: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
          }}
        >
          {article.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <ArticleCard data={item} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};
export default ArticleSlider;
