import React from 'react';

function PublicationProcess() {
  return (
    <div className="p-lg-5 p-3 text-justified">
      <h2 className='mb-5'>Detailed Publication Process</h2>
      <ol>
        <li>
          Authors may submit their manuscripts by accessing the “Post Research Paper” option available after registering in the “Students Corner” section.
        </li>
        <li>
          Upon receiving the submission, an acknowledgment email containing a unique Manuscript ID will be sent to the author(s). If co-authors are included, they will also receive this notification.
        </li>
        <li>
          The submitted article will be forwarded to two independent reviewers (not from the honorary editorial board) for evaluation.
        </li>
        <li>
          In case of publication, authors and co-authors (if applicable) will be notified of the acceptance or rejection of the manuscript.
        </li>
        <li>
          Authors will be provided with a free DOI, and upon successful publication, students can log into their profile in the “Students Corner” section to download their e-certificates. Please note that the certificates will not be sent in the emails.
        </li>
      </ol>
    </div>
  );
}

export default PublicationProcess;
