import { useLocation , useNavigate  } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { updateArticleByUserApi, getArticleByIdApi } from "../../network/api";
import ReactQuill from "react-quill";

const EditPostArticle = () => {
  const { state } = useLocation();
  const navigate = useNavigate(); 
  const [article, setArticle] = useState(state ? state.article : null);
  console.log("article", article);
  const [formData, setFormData] = useState({
    title: "",
    brief: "",
    image: null,
    content: "",
    hashtag: "",
    metaKeywords: "",
  });

  const [isLoading, setisLoading] = useState(false);
  useEffect(() => {
    if (!article) {
      const fetchArticle = async () => {
        try {
        
          console.log(article)
          const response = await getArticleByIdApi(article.Id); 
          if (response.status === 200 || response.data.status === 200) {
            toast.success(response.data.message);
          setArticle(response.data);  
          setFormData({
            title: response.data.title,
            brief: response.data.brief,
            content: response.data.content,
            hashtag: response.data.hashtag,
            metaKeywords: response.data.metaKeywords,
          });
        }
        } catch (error) {
          toast.error("Failed to fetch article data.");
        }
      };
      fetchArticle();
    } else {
      setFormData({
        title: article.title,
        brief: article.brief,
        content: article.content,
        hashtag: article.hashtag,
        metaKeywords: article.metaKeywords,
      });
    }
  }, [article]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageChange = (e) => {
    setFormData({
      ...formData,
      image: e.target.files[0],
    });
  };

  const handleContentChange = (content) => {
    setFormData({
      ...formData,
      content: content,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setisLoading(true);
      const submitData = new FormData();
      Object.keys(formData).forEach((key) => {
        submitData.append(key, formData[key]);
      });
      console.log(formData);

      const response = await updateArticleByUserApi(article._id, submitData);
      if (response.status === 200 || response.data.status === 200) {
        toast.success("Article updated successfully!");
        navigate("/student-corner/my-articles");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error submitting the article:", error);
      toast.error("An error occurred while updating the article.");
    } finally {
      setisLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-floating mb-3">
        <h5 className="mt-4 mb-4">Edit Article</h5>

        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            id="titleInput"
            name="title"
            value={formData.title}
            onChange={handleChange}
            placeholder="Enter title"
            required
          />
          <label htmlFor="titleInput">Title</label>
        </div>

        <div className="form-floating">
          <textarea
            className="form-control"
            id="briefTextarea"
            name="brief"
            value={formData.brief}
            onChange={handleChange}
            placeholder="Enter brief description"
            required
          ></textarea>
          <label htmlFor="briefTextarea">Brief</label>
        </div>

        <div className="input-group mt-3">
          <label className="input-group-text" htmlFor="inputGroupFile01">
            Upload Image
          </label>
          <input
            type="file"
            className="form-control"
            id="inputGroupFile01"
            onChange={handleImageChange}
          />
        </div>

        <div className="form-floating mt-3">
          <div className="mt-3">
            <label htmlFor="content">Content</label>
            <ReactQuill
              className="content"
              theme="snow"
              value={formData.content}
              onChange={handleContentChange}
              required
            />
          </div>
        </div>


        <div className="form-floating mt-3">
          <select
            className="form-select pt-2"
            id="hashtagSelect"
            name="hashtag"
            value={formData.hashtag}
            onChange={handleChange}
            required
          >
            <option disabled value="">
              Hashtag
            </option>
            <option value="technology">Technology</option>
            <option value="media">Media</option>
            <option value="education">Education</option>
            <option value="business">Business</option>
            <option value="art-culture">Art & Culture</option>
            <option value="sports">Sports</option>
            <option value="policy">Policy</option>
            <option value="management">Management</option>
            <option value="environment">Environment</option>
            <option value="humanities">Humanities</option>
            <option value="wellness">Wellness</option>
          </select>
          {/* <label htmlFor="hashtagSelect">Hashtag</label> */}
        </div>

        <div className="form-floating mt-3">
          <input
            type="text"
            className="form-control"
            id="metaKeywordsInput"
            name="metaKeywords"
            value={formData.metaKeywords}
            onChange={handleChange}
            placeholder="Enter keywords"
          />
          <label htmlFor="metaKeywordsInput">Meta Keywords</label>
        </div>
        {isLoading ? (
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        ) : (
          <div className="mt-4 mb-3">
            <input
              className="btn btn-primary mt-3"
              type="submit"
              value="Submit"
            />
          </div>
        )}
      </div>
    </form>
  );
};

export default EditPostArticle;
