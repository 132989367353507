import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/slices/authSlice";
import { getUserProfileApi, updateUserProfileApi } from "../../network/api";
import { toast } from "react-toastify";
import EditorialBoardView from "./EditorialBoardView";
import { getCurrentMonth } from "../../utils/appConfig";

const StudentProfilePage = () => {
  const dispatch = useDispatch();

  const blankProfile = {
    name: "",
    email: "",
    DOB: "",
    collage_name: [],
    subject_stream: [],
    linkedin_profile: "",
    research_interest: "",
    image: "", // Image URL
    articlesPosted: 0,
  };
  const [editProfile, setEditProfile] = useState(false);
  const [data, setData] = useState(blankProfile);
  const [collegeName, setCollegeName] = useState([]);
  const [subjectStream, setSubjectStream] = useState([]);
  const [newColleges, setNewColleges] = useState([]);
  const [newSubjects, setNewSubjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showForm, setShowForm] = useState(false); // Toggle for academic form
  const [showSocialForm, setShowSocialForm] = useState(false); // Toggle for social profile form
  const [error, setError] = useState(false);

  const fetchProfile = async () => {
    try {
      setIsLoading(true);
      const response = await getUserProfileApi();
      if (response.status === 200 && response.data.status === 200) {
        setCollegeName(response.data.data.collage_name || []);
        setSubjectStream(response.data.data.subject_stream || []);
        setData({
          ...response.data.data,
          articlesPosted: response.data.data.articlesPosted || 0,
        });
      } else {
        dispatch(logout());
      }
    } catch (e) {
      console.error("Fetch Error:", e);
      toast.error(e.message);
      setData(blankProfile);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  const handleInputChange = (event) => {
    const { id, value } = event.target;

    // Handle the contact input separately
    if (id === "contact") {
      const mobileRegex = /^[6-9]\d{9}$/;
      if (mobileRegex.test(value) || value === "") {
        setData((prevData) => ({
          ...prevData,
          [id]: value,
          error: "",
        }));
        setError(false);
      } else {
        setData((prevData) => ({
          ...prevData,
          [id]: value,
        }));
        setError(true);
      }
    } else {
      // For all other inputs
      setData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };
  const handleSaveProfile = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("DOB", data.DOB);
    formData.append("contact", data.contact);
    if (selectedImage) {
      formData.append("image", selectedImage);
    }

    try {
      const response = await updateUserProfileApi(formData);
      if (response.status === 200 || response.data.status === 200) {
        toast.success("Profile updated successfully!");
        setEditProfile(false);
        fetchProfile();
      } else {
        toast.error("Update failed.");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Failed to update profile.");
    }
  };
  // Academic form submission handler
  const handleAcademicSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append(
        "collage_name",
        [...collegeName, ...newColleges].join(", ")
      );
      formData.append(
        "subject_stream",
        [...subjectStream, ...newSubjects].join(", ")
      );

      const response = await updateUserProfileApi(formData);
      if (response.status === 200 || response.data.status === 200) {
        setCollegeName([...collegeName, ...newColleges]);
        setSubjectStream([...subjectStream, ...newSubjects]);
        setNewColleges([]);
        setNewSubjects([]);
        toast.success(response.data.message);
        setShowForm(false); // Hide form after successful update
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error submitting academic details:", error);
      toast.error(error.message);
    }
  };

  // Social form submission handler
  const handleSocialSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append("linkedin_profile", data.linkedin_profile);
      formData.append("research_interest", data.research_interest);

      const response = await updateUserProfileApi(formData);
      if (response.status === 200 || response.data.status === 200) {
        toast.success(response.data.message);
        setShowSocialForm(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error submitting social profile details:", error);
      toast.error(error.message);
    }
  };

  return (
    <div className=" mt-4">
      <div className="d-flex justify-content-between mb-3">
        <h5 className="text-primary">Personal Details</h5>
        &nbsp;
        <button
          type="button"
          className="btn btn-sm btn-success"
          onClick={() => {
            setEditProfile(!editProfile);
          }}
        >
          {editProfile ? (
            "Cancel"
          ) : (
            <>
              <i className="bx bx-edit"></i> Edit
            </>
          )}
        </button>
      </div>

      <div className="mb-5">
        <div className="row">
          <div className="col-md-8">
            {editProfile ? (
              <form onSubmit={handleSaveProfile}>
                <div className="mb-3">
                  <label className="form-label">Profile Image:</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={handleImageChange}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">DOB:</label>
                  <input
                    type="date"
                    className="form-control"
                    id="DOB"
                    value={data.DOB}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-2">
                  <label className="form-label">Mobile:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="contact" // Ensure this matches the state key
                    value={data.contact} // Binds input value to state
                    onChange={handleInputChange} // Calls handleInputChange on input change
                  />
                </div>

                {error ? (
                  <>
                    <p className="error-text">
                      Please enter a valid 10-digit mobile number
                    </p>
                    <button
                      type="submit"
                      className="btn btn-primary mb-3"
                      disabled
                    >
                      Save
                    </button>
                  </>
                ) : (
                  <button type="submit" className="btn btn-primary mb-3 mt-2">
                    Save
                  </button>
                )}
              </form>
            ) : (
              <div className="d-flex">
                <img
                  src={data.image ? data.image : "assets/profile-avatar.png"}
                  alt="Profile"
                  className="rounded-circle"
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                  }}
                />
                <div className="ms-3 d-flex flex-column gap-1">
                  <strong>
                    <big>{data.name}</big>
                  </strong>
                  <span>{data.email}</span>
                  <div>
                    <strong>
                      <i className="bx bx-calendar"></i>
                    </strong>
                    &nbsp;{data.DOB}
                  </div>
                  <div>
                    <strong>
                      <i className="bx bx-phone"></i>
                    </strong>
                    &nbsp;{data.contact}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-4 mt-4 mt-md-0">
            <div
              className="border border-dark p-4 text-center"
              style={{ borderStyle: "dashed" }}
            >
              <h5>Article Counter</h5>
              <h1>{data.articlesPosted} /4</h1>
              <p className="mb-0">{getCurrentMonth()}</p>
            </div>
          </div>
        </div>
        <hr />
        <div className="col-12">
          {/* Academic Form */}
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h5 className="text-primary mb-0">Academic Details</h5>
            <button
              className="btn btn-success btn-sm"
              onClick={() => setShowForm(!showForm)}
            >
              {showForm ? "Cancel" : "+ Add"}
            </button>
          </div>

          {showForm ? (
            <form onSubmit={handleAcademicSubmit}>
              <div className="mb-3">
                <label className="form-label">
                  Institutional Affiliation/College Name:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="collage_name"
                  onChange={(e) => setNewColleges([e.target.value])}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">
                  Course Enrolled/Subject Stream:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="subject_stream"
                  onChange={(e) => setNewSubjects([e.target.value])}
                />
              </div>
              <button type="submit" className="btn btn-primary mb-3">
                Save
              </button>
            </form>
          ) : (
            <>
              <p>
                <strong>Institutional Affiliation/College Name:</strong>{" "}
                {collegeName.map((item) => {
                  return <p>{item}</p>;
                })}
              </p>
              <p>
                <strong>Course Enrolled/Subject Stream:</strong>
                {subjectStream.map((item) => {
                  return <p>{item}</p>;
                })}
              </p>
            </>
          )}

          {/* Social Profile Form */}
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h5 className="text-primary mb-0">Social Profile</h5>
            <button
              className="btn btn-success btn-sm"
              onClick={() => setShowSocialForm(!showSocialForm)}
            >
              {showSocialForm ? "Cancel" : "+ Add"}
            </button>
          </div>

          {showSocialForm ? (
            <form onSubmit={handleSocialSubmit}>
              <div className="mb-3">
                <label className="form-label">LinkedIn Profile:</label>
                <input
                  type="text"
                  className="form-control"
                  id="linkedin_profile"
                  value={data.linkedin_profile}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Research Interest Area:</label>
                <input
                  type="text"
                  className="form-control"
                  id="research_interest"
                  value={data.research_interest}
                  onChange={handleInputChange}
                />
              </div>
              <button type="submit" className="btn btn-primary mb-3">
                Save
              </button>
            </form>
          ) : (
            <>
              <p>
                <strong>LinkedIn Profile:</strong> {data.linkedin_profile}
              </p>
              <p>
                <strong>Research Interest Area:</strong>{" "}
                {data.research_interest}
              </p>
            </>
          )}
        </div>
        <hr />
        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-danger d-flex align-items-center"
            onClick={() => dispatch(logout())}
          >
            <i className="bx bx-log-out"></i> &nbsp; Logout
          </button>
        </div>
      </div>
      <div className="p-2">
        <EditorialBoardView />
      </div>
    </div>
  );
};

export default StudentProfilePage;
