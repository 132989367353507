import React, { useState } from "react";
import { postYourStoryApi } from "../network/api";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form"; 
import { ErrorMessage } from "@hookform/error-message";





// const Blankform = {
//   startup_name: "",
//   website_link: "",
//   founder_email: "",
//   founder_contact: "",
//   company_registered_name: "",
//   cin: "",
//   founding_year: "",
//   based_at: "",
//   founding_members_name: "",
//   founder_linkedInUrl: "",
//   company_linkedInUrl: "",
//   startup_overview: {
//     mission_purpose: "",
//     unique_value_proposition: "",
//   },
//   target_audience: "",
//   stage_funding: "",
//   funding_raised: "",
//   current_valuation: "",
//   investor_name: "",
//   product_service_Details: {
//     description: "",
//     benefits: "",
//     technology_used: "",
//     innovation_idea: "",
//     market_validation: "",
//   },
//   revenue: {
//   revenue_model: "",
//   last_year_revenue: "",
//   last_month_revenue: "",
//   future_growth_plans: "",
//   potential_competitors: "",

//   }
// }
const YourStory = () => {
  

  // const [formData, setFormData] = useState(Blankform);

  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setisLoading] = useState(false);


  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },  
  } = useForm();

  // const handleInputChange = (event) => {
  //   const { id, value } = event.target;
  
  
  //   if (id.includes(".")) {
  //     const [parentKey, childKey] = id.split(".");
  
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       [parentKey]: {
  //         ...prevFormData[parentKey],
  //         [childKey]: value,
  //       },
  //     }));
  //   } else {
  //     setFormData({ ...formData, [id]: value });
  //   }
  // };
  

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const onSubmit = async (data) => {
    if (!isChecked) {
      toast.error("You must agree to the terms and conditions before submitting.");
      return;
    }
    try {
      setisLoading(true);
      const response = await postYourStoryApi(data);  
      if (response.status === 200 || response.data.status === 200) {
        toast.success(response.data.message);
        reset()
      
        
        setIsChecked(false);  
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      toast.error(error.response?.data?.message ?? error.message);
    } finally {
      setisLoading(false);
    }
  };

  return (
    <div className="container-fluid">
      <div className="card mx-auto shadow">
        <div className="card-body">
          <h4 className="card-title text-center fontt">
            Every Success Has a story:What's Yours?
          </h4>
          <hr />
          <form onSubmit={handleSubmit (onSubmit)}>
            <div className="row">
              <div className="col-md-6 mb-3 text-start">
                <div className="form-floating">
                  <input
                    type="text"
                    className={`form-control ${errors.startup_name ? "danger-border" : ""}`}
                    // id="startup_name"
                    // value={formData.startup_name}
                    {...register("startup_name", { required: "This field is required",
                      pattern: {
                        value: /^[\s\S]*$/,
                        message: "Invalid value entered",
                        
                      },
                      
                    })}
                    placeholder="Enter Startup Name"
                  
                  />
                  <label htmlFor="startup_name">
                    Name Of Startup <span className="text-danger">*</span>
                  </label>

                  <p className="error-text">
              <ErrorMessage errors={errors} name="startup_name" />
            </p>
                </div>
              </div>
              <div className="col-md-6 mb-3 text-start">
                <div className="form-floating">
                  <input
                    type="text"
                    className={`form-control ${errors.website_link ? "danger-border" : ""}`}
                    // id="website_link"
                    // value={formData.website_link}onChange={handleInputChange}
                    {...register("website_link", {
                      required: false
                      // pattern: {
                      //   value: /^(https?:\/\/)?([\w.-]+)(\.[a-z]{2,6})?([\/\w .-]*)*\/?$/i,
                      //   message: "Please enter a valid URL or website name",
                      // },
                      
                    })}
                    placeholder="URL"
                  />
                  <label htmlFor="website_link">
                   
                    Website/App Link <span className="text-danger">*</span>
                  </label>
                  {/* <p className="error-text">
              <ErrorMessage errors={errors} name="website_link" />
            </p> */}
                </div>
              </div>
            </div>

            <div className="row text-start">
  <div className="col-md-6 mb-3">
    <div className="form-floating">
      <input
        type="email"
        className={`form-control ${errors.founder_email ? "danger-border" : ""}`}
        // id="founder_email"
        // value={formData.founder_email}
        // onChange={handleInputChange}
        {...register("founder_email", {
          required: "This field is required",
          pattern: {
            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: "Please enter a valid email address",
          },
        })}
        placeholder="Email"
      />
      <label htmlFor="founder_email">
        Email Id (Founder)<span className="text-danger">*</span>
      </label>
      <p className="error-text">
              <ErrorMessage errors={errors} name="founder_email" />
            </p>
     
    </div>
  </div>
  <div className="col-md-6 mb-3">
    <div className="form-floating">
      <input
        type="text"
        className={`form-control ${errors.founder_contact ? "danger-border" : ""}`}
        // id="founder_contact"
        // value={formData.founder_contact}
        // onChange={handleInputChange}
        {...register("founder_contact", {
          required: "This field is required",
          pattern: {
            value: /^\d{10}$/, // Pattern for a valid 10-digit number
            message: "Enter a valid 10-digit number",
          },
        })}
        placeholder="Contact"
      />
      <label htmlFor="founder_contact">
        Contact No. (Founder)<span className="text-danger">*</span>
      </label>
      <p className="error-text">
              <ErrorMessage errors={errors} name="founder_contact" />
            </p>
    </div>
  </div>
</div>


<div className="mb-3 text-start">
        <div className="form-floating">
          <input
            type="text"
            className={`form-control ${errors.company_registered_name ? "danger-border" : ""}`}
            id="company_registered_name"
            {...register("company_registered_name", { required: "This field is required" })}
            placeholder="Company Name"
          />
          <label htmlFor="company_registered_name">
            Registered Name of the Company<span className="text-danger">*</span>
          </label>
          <p className="error-text">
              <ErrorMessage errors={errors} name="company_registered_name" />
            </p>
        </div>
      </div>

      <div className="mb-3 text-start">
        <div className="form-floating">
          <input
            type="text"
            className={`form-control ${errors.cin ? "danger-border" : ""}`}
            id="cin"
            {...register("cin", { required: "This field is required" })}
            placeholder="CIN No."
          />
          <label htmlFor="cin">CIN<span className="text-danger">*</span></label>
          <p className="error-text">
              <ErrorMessage errors={errors} name="cin" />
            </p>
        </div>
      </div>

      <div className="row text-start">
        <div className="col-md-6 mb-3">
          <div className="form-floating">
            <input
              type="text"
              className={`form-control ${errors.founding_year ? "danger-border" : ""}`}
              id="founding_year"
              {...register("founding_year", { required: "This field is required" })}
              placeholder="Founding Year"
            />
            <label htmlFor="founding_year">Founding Year<span className="text-danger">*</span></label>
            <p className="error-text">
              <ErrorMessage errors={errors} name="founding_year" />
            </p>
          </div>
        </div>
        <div className="col-md-6 mb-3">
          <div className="form-floating">
            <input
              type="text"
              className={`form-control ${errors.based_at ? "danger-border" : ""}`}
              id="based_at"
              {...register("based_at", { required: "This field is required" })}
              placeholder="Based at"
            />
            <label htmlFor="based_at">Based at<span className="text-danger">*</span></label>
            <p className="error-text">
              <ErrorMessage errors={errors} name="based_at" />
            </p>
          </div>
        </div>
      </div>

      <div className="mb-3 text-start">
        <div className="form-floating">
          <input
            type="text"
            className={`form-control ${errors.founding_members_name ? "danger-border" : ""}`}
            id="founding_members_name"
            {...register("founding_members_name", { required: "This field is required" })}
            placeholder="Founding Members"
          />
          <label htmlFor="founding_members_name">
            Name of Founding Members<span className="text-danger">*</span>
          </label>
          <p className="error-text">
              <ErrorMessage errors={errors} name="founding_members_name" />
            </p>
          
        </div>
      </div>

            <div className="row text-start">
              <div className="col-md-6 mb-3">
                <div className="form-floating">
                  <input
                    type="text"
                    className={`form-control ${errors.founder_linkedInUrl ? "danger-border" : ""}`}
                    id="founder_linkedInUrl"
                    {...register("founder_linkedInUrl", { required: false })}
                    // value={formData.founder_linkedInUrl}
                    // onChange={handleInputChange}
                    placeholder="Founder Linkedin"
                  />
                  <label htmlFor="founder_linkedInUrl">
                    LinkedIn Url (Founder)
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="form-floating">
                  <input
                    type="text"
                    className={`form-control ${errors.company_linkedInUrl ? "danger-border" : ""}`}
                    id="company_linkedInUrl"
                    {...register("company_linkedInUrl", { required: false })}
                    // value={formData.company_linkedInUrl}
                    // onChange={handleInputChange}
                    placeholder="Company Linkedin"
                  />
                  <label htmlFor="company_linkedInUrl">
                    LinkedIn Url (Company)
                  </label>
                </div>
              </div>
            </div>

            <h6 className="mt-4 text-start">Startup Overview</h6>
            <hr />

            <div className="row text-start">
        <div className="col-md-6 mb-3">
          <div className="form-floating">
            <input
              type="text"
              className={`form-control ${errors.startup_overview?.mission_purpose ? "danger-border" : ""}`}
              id="startup_overview.mission_purpose"
              {...register("startup_overview.mission_purpose", { required: "Mission is required" })}
              placeholder="Mission"
            />
            <label htmlFor="startup_overview.mission_purpose">
              Mission of your Startup<span className="text-danger">*</span>
            </label>
            <p className="error-text">
              <ErrorMessage errors={errors} name="startup_overview.mission_purpose" />
            </p>
          </div>
        </div>
        <div className="col-md-6 mb-3">
          <div className="form-floating">
            <input
              type="text"
              className={`form-control ${errors.startup_overview?.unique_value_proposition ? "danger-border" : ""}`}
              id="startup_overview.unique_value_proposition"
              {...register("startup_overview.unique_value_proposition", { required: "Value Proposition is required" })}
              placeholder="Proposition"
            />
            <label htmlFor="startup_overview.unique_value_proposition">
              Unique Value Proposition<span className="text-danger">*</span>
            </label>
            <p className="error-text">
              <ErrorMessage errors={errors} name="startup_overview.unique_value_proposition" />
            </p>
          
          </div>
        </div>
      </div>

      <div className="mb-3 text-start">
        <div className="form-floating">
          <input
            type="text"
            className={`form-control ${errors.target_audience ? "danger-border" : ""}`}
            id="target_audience"
            {...register("target_audience", { required: "Target Audience is required" })}
            placeholder="Audience"
          />
          <label htmlFor="target_audience">Target Audience<span className="text-danger">*</span></label>
          <p className="error-text">
              <ErrorMessage errors={errors} name="target_audience" />
            </p>
        </div>
      </div>

            <h6 className="mt-4 text-start">Funding Details</h6>
            <hr />

            <div className="row text-start">
              <div className="col-md-6 mb-3">
                <div className="form-floating">
                  <input
                    type="text"
                    className={`form-control ${errors.stage_funding ? "danger-border" : ""}`}
                    id="stage_funding"
                    {...register("stage_funding", { required: false })}
                    // value={formData.stage_funding}
                    // onChange={handleInputChange}
                    placeholder="Stage"
                  />
                  <label htmlFor="stage_funding">Stage of Funding</label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="form-floating">
                  <input
                    type="text"
                    className={`form-control ${errors.funding_raised ? "danger-border" : ""}`}
                    id="funding_raised"
                    {...register("funding_raised", { required: false })}
                    // value={formData.funding_raised}
                    // onChange={handleInputChange}
                    placeholder="Amount"
                  />
                  <label htmlFor="funding_raised">
                    Funding Raised (in INR)
                  </label>
                </div>
              </div>
            </div>

            <div className="row text-start">
              <div className="col-md-6 mb-3">
                <div className="form-floating">
                  <input
                    type="text"
                    className={`form-control ${errors.current_valuation ? "danger-border" : ""}`}
                    id="current_valuation"
                    {...register("current_valuation", { required: false })}
                    // value={formData.current_valuation}
                    // onChange={handleInputChange}
                    placeholder="Valuation"
                  />
                  <label htmlFor="current_valuation">
                    Current Valuation (in INR)
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="form-floating">
                  <input
                    type="text"
                    className={`form-control ${errors.investor_name ? "danger-border" : ""}`}
                    id="investor_name"
                    {...register("investor_name", { required: false })}
                    // value={formData.investor_name}
                    // onChange={handleInputChange}
                    placeholder="Investor(s)"
                  />
                  <label htmlFor="investor_name">Investor Name(s)</label>
                </div>
              </div>
            </div>

            <h6 className="mt-4 text-start">Product/Service Details</h6>
            <hr />

            <div className="mb-3 text-start">
        <div className="form-floating">
          <input
            type="text"
            className={`form-control ${errors.product_service_Details?.description ? "danger-border" : ""}`}
            id="product_service_Details.description"
            {...register("product_service_Details.description", {
              required: "Product / Service description is required",
            })}
            placeholder="Describe Your Services/Product"
          />
          <label htmlFor="product_service_Details.description">
            Product / Service Description<span className="text-danger">*</span>
          </label>
          <p className="error-text">
              <ErrorMessage errors={errors} name="product_service_Details.description" />
            </p>
        </div>
      </div>

      <div className="mb-3 text-start">
        <div className="form-floating">
          <input
            type="text"
            className={`form-control ${errors.product_service_Details?.benefits ? "danger-border" : ""}`}
            id="product_service_Details.benefits"
            {...register("product_service_Details.benefits", {
              required: "Key Features / Benefits are required",
            })}
            placeholder="Key Features / Benefits"
          />
          <label htmlFor="product_service_Details.benefits">
            Key Feature / Benefits<span className="text-danger">*</span>
          </label>
          <p className="error-text">
              <ErrorMessage errors={errors} name="product_service_Details.benefits" />
            </p>
        </div>
      </div>

            <div className="row text-start">
              <div className="col-md-6 mb-3">
                <div className="form-floating">
                  <input
                    type="text"
                    className={`form-control ${errors.product_service_Details?.technology_used ? "danger-border" : ""}`}
                    id="product_service_Details.technology_used"
                    {...register("product_service_Details.technology_used", { required: false })}
                    // onChange={handleInputChange}
                    // value={formData.product_service_Details.technology_used}
                    placeholder="Technology Used"
                  />
                  <label htmlFor="product_service_Details.technology_used">Technology Used</label>
                </div>
              </div>
           
      <div className="col-md-6 mb-3">
        <div className="form-floating">
          <input
            type="text"
            className={`form-control ${errors.product_service_Details?.innovation_idea ? "danger-border" : ""}`}
            id="product_service_Details.innovation_idea"
            {...register("product_service_Details.innovation_idea", {
              required: "Innovation in Idea is required",
            })}
            placeholder="Innovation in Idea"
          />
          <label htmlFor="product_service_Details.innovation_idea">
            Innovation in Idea<span className="text-danger">*</span>
          </label>
          <p className="error-text">
              <ErrorMessage errors={errors} name="product_service_Details.innovation_idea" />
            </p>
        </div>
      </div>
      </div>

            <div className="mb-3 text-start">
              <div className="form-floating">
                <input
                  type="text"
                  className={`form-control ${errors.product_service_Details?.market_validation ? "danger-border" : ""}`}
                  id="product_service_Details.market_validation"
                  {...register("product_service_Details.market_validation", { required: false })}
                  // onChange={handleInputChange}
                  // value={formData.product_service_Details.market_validation}
                  placeholder="Feedbacks / testimonials"
                />
                <label htmlFor="formMarketValidation">
                  Market Validation (any feedback, testimonials, etc.)
                </label>
              </div>
            </div>

            <h6 className="mt-4 text-start">Revenue Streams</h6>
            <hr />

            <div className="row text-start">
              <div className="col-md-6 mb-3">
                <div className="form-floating">
                  <input
                    type="text"
                    className={`form-control ${errors.revenue?.last_year_revenue ? "danger-border" : ""}`}
                    id="revenue.last_year_revenue"
                    {...register("revenue.last_year_revenue", { required: false })}
                    // value={formData.revenue.last_year_revenue}
                    // onChange={handleInputChange}
                    placeholder="Last Year Revenue"
                  />
                  <label htmlFor="last_year_revenue">
                    Revenue in Last Year
                  </label>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="form-floating">
                  <input
                    type="text"
                    className={`form-control ${errors.revenue?.last_month_revenue ? "danger-border" : ""}`}
                    id="revenue.last_month_revenue"
                    {...register("revenue.last_month_revenue", { required: false })}
                    // value={formData.revenue.last_month_revenue}
                    // onChange={handleInputChange}
                    placeholder="Last Month Revenue"
                  />
                  <label htmlFor="last_month_revenue">
                    Revenue in Last Month
                  </label>
                </div>
              </div>
            </div>

            <div className="row text-start">
        <div className="col-md-12 mb-3">
          <div className="form-floating">
            <input
              type="text"
              className={`form-control ${errors.revenue?.revenue_model ? "danger-border" : ""}`}
              id="revenue.revenue_model"
              {...register("revenue.revenue_model", {
                required: "Revenue Model is required",
              })}
              placeholder="Revenue Model"
            />
            <label htmlFor="revenue_model">Revenue Model<span className="text-danger">*</span></label>
            <p className="error-text">
              <ErrorMessage errors={errors} name="revenue.revenue_model" />
            </p>
          </div>
        </div>
      </div>

      <div className="mb-3 text-start">
        <div className="form-floating">
          <input
            type="text"
            className={`form-control ${errors.revenue?.future_growth_plans ? "danger-border" : ""}`}
            id="revenue.future_growth_plans"
            {...register("revenue.future_growth_plans", {
              required: "Future Growth Plans are required",
            })}
            placeholder="Growth Plans"
          />
          <label htmlFor="future_growth_plans">Future Growth Plans<span className="text-danger">*</span></label>
          <p className="error-text">
              <ErrorMessage errors={errors} name="revenue.future_growth_plans" />
            </p>
        </div>
      </div>

      <div className="mb-3 text-start">
        <div className="form-floating">
          <input
            type="text"
            className={`form-control ${errors.revenue?.potential_competitors ? "danger-border" : ""}`}
            id="revenue.potential_competitors"
            {...register("revenue.potential_competitors", {
              required: "Potential Competitors are required",
            })}
            placeholder="Competitors"
          />
          <label htmlFor="potential_competitors">
            Potential Competitors<span className="text-danger">*</span>
          </label>
          <p className="error-text">
              <ErrorMessage errors={errors} name="revenue.potential_competitors" />
            </p>
        </div>
      </div>


            <div className="form-check mb-3 text-start">
              <input
                type="checkbox"
                className="form-check-input"
                id="disclaimerCheckbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <label className="form-check-label" htmlFor="disclaimerCheckbox">
                I agree to the terms and conditions:
                <ul>
                  <li>
                    The information given by me is true to the best of my
                    knowledge and what I comprehend to be achieved in the near
                    future.
                  </li>
                  <li>
                    In the event that any information provided by me is later
                    found to be false or misleading after thorough examination
                    by “The Maximus Report” team, I acknowledge that it will not
                    be published, and I accept full responsibility for any
                    consequences that may arise as a result of such
                    inaccuracies.
                  </li>
                  <li>
                    I understand that “The Maximus Report” reserves the right to
                    conduct its own due diligence to verify the accuracy of the
                    information provided and if not found correct will not be
                    published.
                  </li>
                </ul>
              </label>
            </div>


            {isLoading ? (
              <div className="spinner-border" role="status">
                <span  className="sr-only"></span>
              </div>
            ) : (
              <button
              type="submit"
              className="btn btn-primary w-100 mb-3"
              style={{ backgroundColor: "rgb(33 37 41)", color: "white" }}
              disabled={!isChecked}
            >
              Submit
            </button>
            )}


            
          </form>
        </div>
      </div>
    </div>
  );
};

export default YourStory;