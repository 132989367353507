import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { getAllArticleApi } from "../network/api";

import { HASHTAG } from "../utils/enums";
import InternshipJob from "../components/InternshipJob";
import Testimonial from "../components/Testimonial";
import Pagination from "../components/Pagination";
import Modal from "../components/Modal";
import { useLocation } from "react-router-dom";


export default function JobsInternshipsPage() {
  const [article, setArticle] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const location = useLocation();


  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return params.get("search") || "";
  };
  
  const fetchArticleDetails = async () => {
    try {
      const title = getQueryParams();
      let response = await getAllArticleApi(page,HASHTAG.JOBS_INTERNSHIP,title);
      if (response.status === 200 || response.data.status === 200) {
        setArticle(response.data.data);
      }
      setTotal(response.data.totalPages);
    } catch (e) {
      console.log("Fetch Error:", e);
      toast.error(e.message);
    }
  };
  useEffect(() => {
    fetchArticleDetails();
  }, [page,location.search]);

  return (
    <>
    <Modal />
      <h1>Hunt the Opportunities</h1>

      {article.length > 0 ? (
  article.map((data, index) => (
    <div className="col-12" key={index}>
      <InternshipJob key={index} data={data} />
    </div>
  ))
) : (
  <div className="d-flex align-items-center justify-content-center" style={{ minHeight: '200px' }}>
    <p className="text-center"><i>No results to show</i></p>
  </div>
)}

       {article.length > 0 && (
          <Pagination total={total} page={page} setPage={setPage} />
        )}


       <div className="mt-4">
      <Testimonial/>
      </div>
    </>
  );
}