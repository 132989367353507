import React from 'react';

function RefundCancellation() {
  return (
    <div className="p-lg-5 p-3 text-justified">
      <h2 className='mb-5'>Refund and Cancellation Policy</h2>
      <p>
        The Maximus Report does not permit refunds or cancellations under the following circumstances:
      </p>
      <ul>
        <li>If an author requests to withdraw a paper after it has been published.</li>
        <li>If the manuscript is rejected due to plagiarism or for failing to meet any conditions outlined in the disclaimer.</li>
        <li>
          If multiple payments are made for the same manuscript, or if an excess amount is debited from the author’s account, The Maximus Report will refund the extra amount within 7–10 working days, to the original payment mode.
        </li>
      </ul>
    </div>
  );
}

export default RefundCancellation;
