import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getWebSiteData } from "../redux/slices/websiteSlice";

function Carousel() {
  const dispatch = useDispatch();
  const carouselImages = useSelector((state) => state.website?.carousel || []);
  function handleClick() {
    let selectedItem = document.querySelector(".carousel-item.active");
    let link = selectedItem.getAttribute("data-redirect");
    if (link) {
      if(!link.startsWith('http')){
        link = `http://${link}`;
      }
      window.open(link,'_blank');
      // window.location.href = ;
    }
  }
  const myState = useSelector((state) => state.website.carousel);
   if (myState.length == 0)
    return null

  return (
    <div
      id="carouselExampleIndicators"
      className="carousel slide"
      data-bs-ride="carousel"
      style={{cursor: "pointer" }}
      onClick={() => handleClick()}
    >
      <div className="carousel-indicators">
        {carouselImages.map((item, index) => (
          <button
            key={index}
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to={index}
            className={index === 0 ? "active" : ""}
            aria-current={index === 0 ? "true" : ""}
            aria-label={`Slide ${index + 1}`} // Fixed this part
            // onClick={() => handleCarousel(item.redirectLink)}
          ></button>
        ))}
      </div>
      <div className="carousel-inner">
        {carouselImages.map((image, index) => (
          <div
            // onClick={() => handleCarousel(image.redirectLink)}
            key={index}
            className={`carousel-item ${index === 0 ? "active" : ""}`} // Fixed className interpolation
            // onClick={() => handleCarousel(image.redirectLink)}
            data-redirect = {image.redirectLink}          >
            <img
              src={image.image} // Assuming each carousel image object has a url property
              className="d-block w-100 carousel-image"
              alt={`Slide ${index + 1}`} // Fixed this part
              style={{ zIndex: 10 }}
            />
          </div>
        ))} 
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="prev"
        onClick={(e) => e.stopPropagation()}
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="next"
        onClick={(e) => e.stopPropagation()}
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
}

export default Carousel;
