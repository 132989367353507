

// export const API_BASE_URL = 'http://139.59.35.189:5050';
export const API_BASE_URL = 'https://backend.themaximusreport.com';
// export const API_BASE_URL = 'http://localhost:5050';

export const ENDPOINTS = {
    login: API_BASE_URL + '/api/login',
    googleLogin: API_BASE_URL + '/api/google-login',
    register: API_BASE_URL + '/web-api/web-register',
    getWebsiteData:  API_BASE_URL + '/web-api/web',
    contactUsData:  API_BASE_URL + '/web-api/contact-us',
    postYourStory: API_BASE_URL + '/web-api/user-story',
    subscribeNewsletterData:  API_BASE_URL + '/web-api/subscribe',
    getAllArticle: API_BASE_URL + '/web-api/articles',
    getHighlighArticle: API_BASE_URL + '/web-api/highlight-articles',
    getArticleById: API_BASE_URL + '/web-api/article',
    getTakeALook: API_BASE_URL + '/web-api/takeA-look',
    postUserArticle: API_BASE_URL + '/user-api/user/article',
    updateUserProfile: API_BASE_URL + '/user-api/user/update-userProfile',
    createOrganizationDetails: API_BASE_URL + '/web-api/setInformation',
    getUserProfile: API_BASE_URL + '/user-api/user/profile',
    userArticleView: API_BASE_URL + '/user-api/user/user-articles',
    getPageData: API_BASE_URL + '/web-api/web/pages',
    postResearchPaper: API_BASE_URL + '/user-api/user/post-research',
    getResearchPaperByUser: API_BASE_URL + '/user-api/user/get-research',
    updateArticleByUser: API_BASE_URL + '/user-api/user/update-article',
    getApprovedResearchByIdOnWeb: API_BASE_URL + '/web-api/web-research',
    downloadCertificatePdfByUser: API_BASE_URL + '/user-api/user/download-certificate',
    forgetPassword: API_BASE_URL + '/web-api/forgot-password',
    ResetPassword: API_BASE_URL + '/web-api/reset-password',
    getActiveAds: API_BASE_URL + '/web-api/active-ads',
    getApprovedResearchOnWeb: API_BASE_URL + '/web-api/get-researches',
    getAllIndexingByUser: API_BASE_URL + '/web-api/get-indexes',
}


