import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ResetPasswordApi } from "../../network/api";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false); 

  useEffect(() => {
    console.log("Token from URL:", token);
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match!");
    } else if (password.length < 6) {
      setErrorMessage("Password must be at least 6 characters long.");
    } else {
      setLoading(true);
      try {
        const payload = {
          password,
          token,
        };

        const response = await ResetPasswordApi(payload);

        if (response.status === 200 || response.data.status === 200) {
          toast.success(response.data.message);
          navigate("/student-corner");
        } else {
          toast.error(response.data.message || "Failed to reset password. Please try again.");
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("Failed to reset password. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Set New Password</h2>

      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
      {successMessage && <div className="alert alert-success">{successMessage}</div>}

      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">New Password</label>
          <input
            type="password"
            className="form-control"
            id="password"
            placeholder="Enter new password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            disabled={loading}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="confirmPassword" className="form-label">Confirm New Password</label>
          <input
            type="password"
            className="form-control"
            id="confirmPassword"
            placeholder="Confirm new password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            disabled={loading} 
          />
        </div>

        <button type="submit" className="btn btn-primary w-100" disabled={loading}>
          {loading ? "Setting Password..." : "Set Password"} 
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;
