import React from 'react';

const VerificationComplete = () => {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center bg-light mt-5">
            <div className="card p-5 shadow-lg text-center">
                <p className="lead">Your account has been successfully verified.</p>
            </div>
        </div>
    );
};

export default VerificationComplete;
