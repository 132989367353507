import { useSelector } from "react-redux";
import {  useNavigate } from "react-router-dom";

const UserProfileBar = () => {
    const navigate = useNavigate()
  const auth = useSelector((state) => state.auth);
  if(!auth?.token){
    return null;
  }
  return (
    <>
      <div className="d-flex flex-row-reverse">
        <div className="mt-2">
          <button
            type="button"
            className="btn btn-outline-dark py-0"
            style={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderRight: 0,
            }}
            onClick={()=>navigate('student-corner')}
          >
            {" "}
            Hello {auth?.student?.name ?? "User"}
          </button>
        </div>
      </div>
    </>
  );
};

export default UserProfileBar;
