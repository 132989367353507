import React from "react";
import EditorProfileItem from "../../components/EditorProfileItem";

const EditorialBoardView = () => {
  const editors = [
    {
      name: "Dr. Jane Doe",
      title: "Senior Editor",
      email: "janedoe@example.com",
      university: "Harvard University",
      location: "Cambridge, MA, USA",
      image: "https://standrewscollege.ac.in/wp-content/uploads/2019/07/portrait-square-03.jpg",
    },
    {
      name: "Prof. John Smith",
      title: "Assistant Editor",
      email: "johnsmith@example.com",
      university: "Stanford University",
      location: "Stanford, CA, USA",
      image: "https://standrewscollege.ac.in/wp-content/uploads/2019/07/portrait-square-03.jpg",
    },
    {
      name: "Dr. Emily Brown",
      title: "Associate Editor",
      email: "emilybrown@example.com",
      university: "MIT",
      location: "Boston, MA, USA",
      image: "https://standrewscollege.ac.in/wp-content/uploads/2019/07/portrait-square-03.jpg",
    },

    {
      name: "Dr. Emily Brown",
      title: "Associate Editor",
      email: "emilybrown@example.com",
      university: "MIT",
      location: "Boston, MA, USA",
      image: "https://standrewscollege.ac.in/wp-content/uploads/2019/07/portrait-square-03.jpg",
    },

    {
      name: "Dr. Emily Brown",
      title: "Associate Editor",
      email: "emilybrown@example.com",
      university: "MIT",
      location: "Boston, MA, USA",
      image: "https://standrewscollege.ac.in/wp-content/uploads/2019/07/portrait-square-03.jpg",
    },
    {
      name: "Dr. Emily Brown",
      title: "Associate Editor",
      email: "emilybrown@example.com",
      university: "MIT",
      location: "Boston, MA, USA",
      image: "https://standrewscollege.ac.in/wp-content/uploads/2019/07/portrait-square-03.jpg",
    },
    
  
  ];

  return (
    <div className="container mt-4">
      <div className="row justify-content-center">
        <h1 className="text-center">Honorary Editorial Board</h1>
        <h5 className="my-3 text-center">
          <u>Editor In Chief</u>
        </h5>
        <div className="col-lg-6 col-md-8 col-sm-10 mb-4">
          <EditorProfileItem
            name="Dr. Joen Doe"
            title="Engineer and Tech Lead"
            email="jonedoe@uat.com"
            university="University of Texas, LA"
            location="USA"
            image="https://standrewscollege.ac.in/wp-content/uploads/2019/07/portrait-square-03.jpg"
          />
        </div>
      </div>
      <div className="row justify-content-center">
        <h5 className="my-2 mb-4 text-center">
          <u>Editors</u>
        </h5>
        {editors.map((editor, index) => (
          <div key={index} className="col-lg-4 col-md-6 col-sm-10 mb-4">
            <EditorProfileItem
              name={editor.name}
              title={editor.title}
              email={editor.email}
              university={editor.university}
              location={editor.location}
              image={editor.image}
              column={true}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default EditorialBoardView;
