import React from 'react'

function ManuScript() {
  return (
    <>
    
    <div className="p-lg-5 p-3 text-justified">
    <h2 className='mb-4'>Submit Manuscript</h2>
        <p>To submit a manuscript, please proceed to the “Students Corner” section on our website and create a user profile. Upon successfully creating your profile, a verification email will be sent to the registered email address. Following verification, you may log in to your account, navigate to the “Post Research Paper” section, and complete all required submission details. Please upload your manuscript in either .doc or .docx format and proceed with the payment process.</p>
        <br />
        <p>Upon successful payment, you will receive a confirmation email acknowledging receipt of your payment. Your manuscript will then enter the review process, where it will be evaluated thoroughly, including a comprehensive plagiarism check. The evaluation period typically lasts up to 72 hours, after which you will be informed of the manuscript’s acceptance or rejection.</p>
        <br />
        <p>Please ensure that your submission complies with all applicable legal guidelines. The paper should be free from any content that may infringe copyright, defame individuals or entities, contain objectionable material, or be in violation of any laws or regulations.</p>
      </div>
    </>
  )
}

export default ManuScript