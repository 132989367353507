import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getTakeALookApi } from "../network/api";
import { HASHTAG, HashtagMap } from "../utils/enums";
import { useSelector } from "react-redux";




const SideBarLeft = () => {
  // const location = useLocation();
  const [take, setTake] = useState([]);
  const website = useSelector(state=>state.website);
  const fetchTakeALook = async () => {
    let hashtag = website.activeHashtag || HASHTAG.STARTUP_HUB
    if (hashtag === HASHTAG.JOBS_INTERNSHIP) {
      hashtag = HASHTAG.STARTUP_HUB;
    }
    try {
      let response = await getTakeALookApi(hashtag);
      if (response.status === 200 || response.data.status === 200) {
        
      setTake(response.data.data);
      }
    } catch (e) {
      console.log("Fetch Error:", e);
      toast.error(e.message);
    }
  };

  useEffect(() => {
    fetchTakeALook();
    console.log(website);
  }, [website]);

  return (
    
    <div className="sideleft card">
      <div className="card-title">Take a Look</div>
      <div className="card-body">
        <div className="scroll-container">
          <ul
            className="scrolling-list"
            style={{ animationDuration: `${take.length > 2 ? take.length * 3 : 6}s` }}
          >
            {take.map((item, index) => (
              <Link
                key={index}
                to={`/article/${item._id}`} 
                className="text-black"
              >
                <li className="dark-text d-flex">
                  <span><strong className="text-black"><i className="bx bx-hash pt-1"></i> </strong> {item.title}</span>
                </li>
              </Link>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideBarLeft;
