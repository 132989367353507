import { useParams } from "react-router-dom";
import { getArticleByIdApi } from "../network/api";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setActiveHashtag } from "../redux/slices/websiteSlice";
import { HASHTAG } from "../utils/enums";
import ShareButtons from "../components/ShareButtons";

const ArticleDetailPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [article, setArticle] = useState(null);
  const fetchArticleDetails = async (id) => {
    try {
      let response = await getArticleByIdApi(id);
      if (response.status === 200 || response.data.status === 200) {
        
      setArticle(response.data.data);
      }
    } catch (e) {
      console.log("Fetch Error:", e);
      toast.error(e.message);
    }
  };
  useEffect(() => {
    dispatch(setActiveHashtag(article?.hashtag ?? HASHTAG.STARTUP_HUB));
  }, [article]);

  useEffect(() => {
    fetchArticleDetails(id);
  }, [id]);

  if (!article) {
    return <h1 className="text-center">Please wait...</h1>;
  }

  return (
    <>
      <div className="col-12 p-2  cursor-pointer">
        <div className="">
          <img
            src={article.image}
            className="card-img-top rounded-2"
            alt="Card Image"
            // style={{ height: "65vh" }}
          />

          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center fs-6 fw-light ps-2 pt-2">
              {article.author?.name && (
                <>
                  <i className="bx bxs-user-circle fs-5 text-muted"></i>
                  <span className="ms-1">{article.author.name}</span>
                </>
              )}{" "}
            </div>
            <div className="d-flex align-items-center fs-6 fw-light pe-2 pt-2">
              <i className="bx bx-calendar fs-5 text-muted"></i>
              <span className="ms-1">
                {moment(article.updatedAt).format("MMM Do YY")}
              </span>
            </div>
          </div>

          <div className=" d-flex flex-column mt-3">
            <div className="d-flex ">
              <h5 className="me-1 ms-1">Share On</h5>
            <ShareButtons/>
            </div>
            <h2
              className="card-title text-start "
              style={{  overflow: "hidden" }}
            >
              {article.title}
            </h2>
            <div
              className="article-content text-justified p-1"
              dangerouslySetInnerHTML={{ __html: article.content }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ArticleDetailPage;
