import React, { useState, useEffect, useRef } from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  } from "react-share";
import {
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaCopy,
  FaShareAlt,
} from "react-icons/fa";
import { toast } from "react-toastify";

const ShareButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const shareUrl = window.location.href;
  const title = "Check out this page!";
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareUrl);
    toast.success("Link copied to clipboard!");
    setIsOpen(false);
  };

  // Close the dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div style={{ position: "relative", display: "inline-block" }} ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        style={{ background: "none", border: "none", cursor: "pointer" }}
      >
        <FaShareAlt size={20} />
      </button>

      {isOpen && (
        <div
          style={{
            position: "absolute",
            top: "40px",
            left: "0",
            background: "#fff",
            border: "1px solid #ddd",
            borderRadius: "5px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            padding: "10px",
            zIndex: 1000,
          }}
        >
          <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
            <FacebookShareButton url={shareUrl} quote={title}>
              <FaFacebook size={18} /> Facebook
            </FacebookShareButton>

            <TwitterShareButton url={shareUrl} title={title} style={{ display: 'flex', alignItems: 'center' }}>
  <FaTwitter size={18} style={{ marginRight: '10px' }} />
  <span style={{ marginRight: '5px' }}>Twitter</span>
</TwitterShareButton>


<LinkedinShareButton url={shareUrl} title={title} style={{ display: 'flex', alignItems: 'center' }}>
  <FaLinkedin size={18} style={{ marginRight: '10px' }} />
  <span style={{ marginRight: '5px' }}>Linkedin</span>
</LinkedinShareButton>

            

            <button
              onClick={copyToClipboard}
              style={{
                display: "flex",
                alignItems: "center",
                background: "none",
                border: "none",
                cursor: "pointer",
                padding: "5px 0",
              }}
            >
              <FaCopy size={18} /> Copy Link
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShareButton;
