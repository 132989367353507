import { Outlet, useLocation } from "react-router-dom";
import {
  showAds,
  showIndexingPartners,
  showTakeALook,
} from "./utils/appConfig";
import IndexingPartners from "./components/IndexingPartners";
import SideBarLeft from "./components/SideBarLeft";
import SideBarRight from "./components/SideBarRight";
import { useEffect } from "react";
import { HashtagMap } from "./utils/enums";
import { useDispatch } from "react-redux";
import { setActiveHashtag } from "./redux/slices/websiteSlice";

const ContentWrapper = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (location.pathname != "/user-article") {
      dispatch(setActiveHashtag(HashtagMap[location.pathname]));
    }
  }, [location]);
  return (
    <div className="all-side-bar mt-3">
      <div className="side-bar-left">
        <div className="container-fluid main">
          <div className="row">
            {showTakeALook(location.pathname) && (
              <div className="col-lg-2 col-12">
                <SideBarLeft />
              </div>
            )}
            <div
              className={`${
                showAds(location.pathname) ? "col-lg-8 col-12" : "col"
              } g-0`}
            >
              {children}
            </div>
            {showAds(location.pathname) && (
              <div className="col-lg-2 col-12">
                <SideBarRight />
              </div>
            )}
            {showIndexingPartners(location.pathname) && (
              <div className="col-lg-2 col-12">
                <IndexingPartners />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentWrapper;
