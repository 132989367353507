export const fullWidthPaths= ["/privacy-policy", "/terms-of-use"];
export const leftBarExcludePaths = ["/privacy-policy", "/terms-of-use"];
export const carouselExcludePaths = ["/privacy-policy", "/terms-of-use","/research-paper/"];
export const showTakeALook = (path) => {
    if(fullWidthPaths.includes(path))return false;
    if(path.startsWith('/research-paper/')) return false;
    return true;
}
export const showAds = (path) => {
    if(fullWidthPaths.includes(path))return false;
    if(['/research-paper/','/student-corner'].some((item)=>path.startsWith(item))) return false;
    return true;
}
export const showIndexingPartners = (path) => {
    if(['/research-paper/','/student-corner'].some((item)=>path.startsWith(item))) return true;
    return false;
}

export const showCarousel = (path) => {
    if(carouselExcludePaths.some((ex)=>path.startsWith(ex))) return false;
    return true;
}

export const getCurrentMonth = () =>{
    let dict = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    let date = new Date();
    let m = dict[date.getMonth()];
    return `${m} ${date.getFullYear()}`;
}