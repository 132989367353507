import React from "react";
import moment from 'moment';
function InternshipJob({ data }) {
  return (
    <>
      <div className="card ms-1 mt-4 mb-4">
        <div className="card-body ms-1 mt-2">
          <h6 className="card-subtitle text-body-secondary text-start ms-2 ">
            {moment (data.updatedAt). format("MMM Do YY")}
          </h6>
          <h5 className="card-title text-start mt-2">{data.title}</h5>
          <h6 className="card-subtitle text-body-secondary text-start ms-1 mb-3">
            {data.brief}
          </h6>
         
       
          <div  className="text-start ms-1 mb-3"  dangerouslySetInnerHTML={{ __html: data.content }}></div>
        </div>
      </div>
    </>
  );
}

export default InternshipJob;
