import { toast } from "react-toastify";
import { getPageDataApi } from "../network/api";
import { useEffect, useState } from "react";

const TermsOfUsePage = () => {
  const [data, setData] = useState("");

  const fetchPrivacy = async () => {
    try {
      let response = await getPageDataApi("terms");
      if (response.status === 200 || response.data.status === 200) {
        console.log(response.data.data);
        setData(response.data.data.terms);
      }
    } catch (e) {
      console.log("Fetch Error:", e);
      toast.error(e.message);
    }
  };

  useEffect(() => {
    fetchPrivacy();
  }, []);

  return (
    <div className="p-lg-5 p-3 text-justified">
      <div className="content" dangerouslySetInnerHTML={{ __html: data }}></div>
    </div>
  );
};

export default TermsOfUsePage;
