import React from "react";
import GoogleAds from "./GoogleAds";


function SideBarRight() {
  return (
    <>
      <div className=" d card">
      <div className="card-title">Ads</div>
        <div className="card-body ">
        
        <div className="card mb-2" style={{ height: 150 }}>
    <div className="card-body">
        <p className="card-text">Ads</p>
        <GoogleAds />
    </div>
</div>

          <div className="card mb-2" style={{ height: 200 }}>
            <div className="card-body">
              <p className="card-text">Ads</p>
              {/* <a href="#" className="btn btn-primary">
                  Go somewhere
                </a> */}
            </div>
          </div>
          <div className="card mb-2" style={{ height: 200 }}>
            <div className="card-body">
              <p className="card-text">Adss</p>
              {/* <a href="#" className="btn btn-primary">
                  Go somewhere
                </a> */}
            </div>
          </div>
          <div className="card mb-2" style={{ height: 200 }}>
            <div className="card-body">
              <p className="card-text">Adss</p>
              {/* <a href="#" className="btn btn-primary">
                  Go somewhere
                </a> */}
            </div>
          </div>
          <div className="card mb-2" style={{ height: 200 }}>
            <div className="card-body">
              <p className="card-text">Adss</p>
              {/* <a href="#" className="btn btn-primary">
                  Go somewhere
                </a> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SideBarRight;
