// src/GoogleAd.js
import React, { useEffect } from 'react';

const GoogleAd = ({ style }) => {
    useEffect(() => {
        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (e) {
            console.error('Adsbygoogle push error:', e);
        }
    }, []);

    return (
        <div style={style}>
            <ins 
                className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-client="ca-pub-6819000130829479"
                data-ad-slot="9795933349"
                data-ad-format="auto"
                data-full-width-responsive="true"
            ></ins>
        </div>
    );
};

export default GoogleAd;
