import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
const ScrollToTop = () => {
  const { pathname } = useLocation();
  const [currentPath, setPath] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPath]);

  useEffect(() => {
    let tempPath = pathname.split("/")[1];
    if (tempPath != undefined && tempPath != currentPath) {
      setPath(tempPath);
    }
  }, [pathname]);
};
export default ScrollToTop;
