import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getWebSiteData } from "../redux/slices/websiteSlice";
import { getWebsiteDataApi, subscribeNewsletterDataApi } from "../network/api";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";

const Footer = () => {
  const websiteData = useSelector((state) => state.website);

  const [email, setEmail] = useState("");
  const [isLoading, setisLoading] = useState(false);

  const handleSubscribe = async () => {
    if (!email) {
      toast.error("Please enter a valid email address");

      return;
    }

    try {
      setisLoading(true);

      const response = await subscribeNewsletterDataApi({ email });
      if (response.status === 200 || response.data.status === 200) {
        toast.success(response.data.message);
        

        setEmail(""); // Clear the input after successful subscription
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      // console.error('Subscription failed:', error);
      // alert('Subscription failed. Please try again.');
    } finally {
      setisLoading(false);
    }
  };

  return (
    <>
      {/* <!-- Footer--> */}

      <div className="container-fluid footer text-center bg-dark text-white py-4">
        <div className="row ms-3">
          <div className="col-lg-4 col-md-6 col-sm-12 mb-3 left-part">
            <h4 className="text-start mt-3 ">The Maximus Report</h4>

            <ul
              className="text-start"
              style={{ listStyleType: "none", paddingLeft: 0 }}
            >
              <li>
                <Link className="nav-link" to={"/privacy-policy"}>
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link className="nav-link" to={"/terms-of-use"}>
                  Terms of Use
                </Link>
              </li>
              <li>
                <Link className="nav-link" to={"/contact"}>
                  Contact Us
                </Link>
              </li>
              <li>
                <Link className="nav-link" to={"/about"}>
                  About Us
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mb-3 text-start mt-3 mid-part">
            <a className="text-start text-white" href="/career">
              {/* Career */}
            </a>
            <br />
            <a className="text-start text-white" href="/about-us">
              {/* About Us */}
            </a>
            <br />
            <a className="text-start text-white" href="/our-team">
              {/* Our Team */}
            </a>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 mb-3 right-part">
            <div className="input-group mt-4">
              <input
                type="email"
                className="form-control newsletter"
                id="basic-url"
                aria-describedby="basic-addon3"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
              />

              <button
                className="subscribe input-group-text bg-dark text-white me-4" // Added margin end (right) here
                id="basic-addon3"
                onClick={handleSubscribe}
                disabled={isLoading}
              >
                Subscribe Newsletter
              </button>
            </div>

            <div className="social-media mt-3">
              <a
                target="_blank"
                href={websiteData?.instagram ?? "#"}
                className="text-white text-decoration-none"
              >
                <img
                  src="/assets/social-img/instagram.svg"
                  alt="Instagram"
                  style={{ width: 20 }}
                />
              </a>
              <a
                href={websiteData?.twitter ?? "#"}
                className="text-white text-decoration-none ms-2"
              >
                <img
                  src="/assets/social-img/twitter.svg"
                  alt="Twitter"
                  style={{ width: 20 }}
                />
              </a>
              <a
                href={websiteData?.facebook ?? "#"}
                className="text-white text-decoration-none ms-2"
              >
                <img
                  src="/assets/social-img/facebook.svg"
                  alt="Facebook"
                  style={{ width: 20 }}
                />
              </a>
              <a
                href={websiteData?.linkedin ?? "#"}
                className="text-white text-decoration-none ms-2"
              >
                <img
                  src="/assets/social-img/linkedin.svg"
                  alt="LinkedIn"
                  style={{ width: 20 }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="disclaimer text-center p-1 bg-dark text-muted border-top">
        {" "}
        ©2024 Quillbeak Technologies, All Rights Reserved.
      </div>
      <ToastContainer position="top-right" autoClose={3000} />
    </>
  );
};

export default Footer;
