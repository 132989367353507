import { useEffect, useState } from "react";
import ArticleCard from "../components/ArticleCard";
import ArticleSlider from "../components/ArticleSlider";
import Testimonial from "../components/Testimonial";
import { toast } from "react-toastify";
import { getAllArticleApi } from "../network/api";
import { HASHTAG } from "../utils/enums";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "../components/Pagination";
import Modal from "../components/Modal";

const LegalReportsPage = () => {
  const navigate = useNavigate();
  const [article, setArticle] = useState([]);
  const [page, setPage] = useState(1);
  const location = useLocation();
  const [total, setTotal] = useState(0);

  const handleArticle = (id) => {
    navigate(`/article/${id}`);
  };

  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return params.get("search") || "";
  };

  const fetchArticleDetails = async () => {
    try {
      const title = getQueryParams();
      const response = await getAllArticleApi(page, HASHTAG.LEGAL_REPORT, title);
      if (response.status === 200 || response.data.status === 200) {
        setArticle(response.data.data);
        setTotal(response.data.totalPages);
      } else {
        toast.error("Failed to fetch articles.");
      }
    } catch (e) {
      console.log("Fetch Error:", e);
      toast.error(e.message);
    }
  };

  useEffect(() => {
    fetchArticleDetails();
  }, [page, location.search]); // Re-run when page or query changes

  return (
    <>
      <Modal />
      <div className="container-fluid mt-1 mb-3">
        <ArticleSlider />
        <h3 className="mb-4">Legal Developments</h3>
        <div className="row">
          {article.length > 0 ? (
            article.map((data, index) => (
              <div
                className="col-sm-6 mb-4"
                key={index}
                onClick={() => handleArticle(data._id)}
              >
                <ArticleCard key={index} data={data} />
              </div>
            ))
          ) : (
            <div className="d-flex align-items-center justify-content-center" style={{ minHeight: '200px' }}>
            <p className="text-center"><i>No results to show</i></p>
          </div>
          )}
        </div>
        {article.length > 0 && (
          <Pagination total={total} page={page} setPage={setPage} />
        )}

        <div className="mt-4">
          <Testimonial />
        </div>
      </div>
    </>
  );
};

export default LegalReportsPage;