import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getApprovedResearchByIdOnWebApi } from "../network/api";
import { useParams } from "react-router-dom";
import { pdfjs, Document, Page } from "react-pdf";
import { Link } from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function ResearchPaperDetailsPage() {
  const { id } = useParams();
  const [article, setArticle] = useState(null); // State to store the article data
  const [pdfFile, setPdfFile] = useState(null); // State to store the PDF file URL
  const [pdfTitle, setPdfTitle] = useState(""); // State to store the PDF title
  const [numPages, setNumPages] = useState(null); // State to store the number of pages in the PDF
  const [currentPage, setCurrentPage] = useState(1); // State to store the current page

  // Fetch research article details
  const fetchArticleDetails = async () => {
    try {
      let response = await getApprovedResearchByIdOnWebApi(id);
      if (response.status === 200 || response.data.status === 200) {
        setArticle(response.data.data); // Store the fetched data in state
        // Show PDF file immediately
        setPdfFile(response.data.data.research_pdf);
        setPdfTitle(response.data.data.research_paper_title);
        setCurrentPage(1); // Start from the first page
      } else {
        toast.error("Failed to fetch article details");
      }
    } catch (e) {
      console.error("Fetch Error:", e);
      toast.error(e.message);
    }
  };

  // Function to handle successful PDF load
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setCurrentPage(1); // Reset to the first page when a new PDF is loaded
  };

  useEffect(() => {
    fetchArticleDetails(id); // Fetch data when component mounts
  }, [id]);

  const downloadPdf = async () => {
    if (pdfFile) {
      try {
        const response = await fetch(pdfFile);
        const blob = await response.blob();
  
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob); 
        link.setAttribute('download', `${pdfTitle}.pdf`); 
  
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
  
        URL.revokeObjectURL(link.href);
      } catch (error) {
        console.error("Download error:", error);
        toast.error("Failed to download PDF file");
      }
    } else {
      toast.error("PDF file not available for download");
    }
  };
  

  return (
    <div className="d-flex flex-column px-3 px-lg-5">
      {article && (
        <>
          {/* Display article information */}
          <div key={article._id} className="row mb-4">
            <div className="col-lg-6 my-3">
              <div className="border p-2" style={{ minHeight: 150, maxHeight: 300 }}>
                <h5 className="text-center">The Maximus Report</h5>
                <p className="text-left">
                  Subject Area - Multi-disciplinary
                </p>
                <p>Accessibility - <span>Open Access</span></p>
                <p>Review Process - <span>Double-Blind Review Process</span></p>
                <p>Frequency - <span>Bi-monthly</span></p>
                <p>Language - <span>English</span></p>
              </div>
            </div>
            <div className="col-lg-6 my-3">
              <div className="border p-2 text-justified" style={{ minHeight: 150, height: 250 }}>
                <h5 className="text-center">Call for papers</h5>
                <p className="text-left">
                  The Maximus Report welcomes authors to submit their research articles for inclusion in{" "}
                  <span className="text-danger">Volume {article.volume}, Issue {article.issue}</span>
                </p>
                <p>Perks - <span>DOI, Certificate of Publication, Timely Process, Highlighted at "Take a Look" section (exceptional quality papers only).</span></p>
              </div>
            </div>
          </div>

          {/* Render the PDF directly on the page */}
          {pdfFile && (
            <div className="col-12 border pdf-viewer  d-flex flex-column align-items-center">
              <h4 className="pdf-title">{pdfTitle}</h4>
              <p className="text-muted">
                Page {currentPage} of {numPages}
              </p>
              <div className="pdf-container w-100" style={{ overflow: 'hidden' }}>
                <Document
                  file={pdfFile}
                  onLoadError={console.error}
                  onLoadSuccess={onDocumentLoadSuccess}
                  className="pdf-document"
                >
                  <Page
                    key={`page_${currentPage}`}
                    pageNumber={currentPage}
                    width={
                      window.innerWidth >= 1200 ? window.innerWidth * 0.7 :   // For large screens (lg)
                      window.innerWidth >= 768 ? window.innerWidth * 0.8 :    // For medium screens (md)
                      window.innerWidth * 0.95                               // For small screens (sm)
                    }
                  />
                </Document>
              </div>
              <div className="navigation mt-3 mb-5">
                <button
                  className="btn btn-secondary me-3"
                  onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                  disabled={currentPage <= 1}
                >
                  Previous
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={() => setCurrentPage(prev => Math.min(prev + 1, numPages))}
                  disabled={currentPage >= numPages}
                >
                  Next
                </button>
              </div>

              {/* Download PDF button */}
              <button className="btn btn-primary mb-4" onClick={downloadPdf}>
                Download PDF
              </button>
            </div>
          )}

          {/* Additional Information */}
          <div className="row">
            <div className="col-lg-4 my-3">
              <div className="border p-2 text-justified" style={{ minHeight: 210 }}>
                <h5 className="text-center">About The Maximus Report Publications</h5>
                <p className="text-left justify-content-end" style={{ fontSize: 13 }}>
                  The Students' Corner at The Maximus Report is a dedicated platform for aspiring scholars and researchers from diverse fields to showcase their work.
                </p>
              </div>
            </div>
            <div className="col-lg-4 my-3">
              <div className="border p-2" style={{ minHeight: 150 }}>
                <h5 className="text-center">Important Links</h5>
                <p className="text-left">
                  <a href="/ResearchPaper">Current Issue</a>
                  <br />
                  <Link to="/manuscript">Submit Manuscript</Link>
                  <br />
                  <Link to="/submission-guidelines">Submission Guidelines</Link>
                  <br />
                  <Link to="/publication-process">Publication Process</Link>                               
                  <br />
                  <Link to="/review-process">Review Process</Link>                         
                  <br />
                  <Link to="/refund-cancellation">Refund and Cancellation</Link>
                </p>
              </div>
            </div>
            <div className="col-lg-4 my-3">
              <div className="border p-2" style={{ minHeight: 210 }}>
                <h5 className="text-center">Contact Details</h5>
                <p className="text-left">
                  The Maximus Report
                  <br />
                  Email - <span>contact@themaximusreport.com</span>
                  <br />
                  Publisher - <span>The Maximus Report</span>
                  <br />
                  Address - <span>Patna, Bihar, India</span>
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ResearchPaperDetailsPage;
