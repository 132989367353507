import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import React, { useState } from "react";

import { Pagination, Navigation, Autoplay } from "swiper/modules";
import TestimonialCard from "./TestimonialCard";
import { getWebsiteDataApi } from "../network/api";
import { toast } from "react-toastify";

import { useEffect } from "react";
import { useSelector } from "react-redux";

const Testimonial = () => {
  const testimonial = useSelector((state) => state.website.testimonials);
  const list = [...testimonial];
  return (
    <div className="row testimonials">
      <div className="col-lg-12 mb-4">
        {list.length > 0 && (
          <Swiper
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              576: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
            }}
            autoplay={{ delay: 3000, disableOnInteraction: true }}
            modules={[Pagination, Navigation, Autoplay]}
            className="swiper"
            navigation
            loop={true}
          >
            {list.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="child">
                    <TestimonialCard data={item} />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </div>
    </div>
  );
};
export default Testimonial;
