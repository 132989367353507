import { useLocation} from "react-router-dom";
import moment from "moment";
import { setActiveHashtag } from "../../redux/slices/websiteSlice";
import { useDispatch } from "react-redux";
import { HASHTAG } from "../../utils/enums";
import { useEffect } from "react";

const StudentArticleView = () => {
  
const dispatch = useDispatch();
const location = useLocation();
const article = location.state.article
  useEffect(()=>{
    dispatch(setActiveHashtag(article.hashtag))
  },[]);
  
  return (
    <>
      <div className="col-12  cursor-pointer">
        <div className="">
          <img
            src={article.image}
            className="card-img-top rounded-2"
            alt="Card Image"
            style={{ height: "65vh" }}
          />

          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center fs-6 fw-light ps-2 pt-2">
              {article.author?.name && (
                <>
                  <i className="bx bxs-user-circle fs-5 text-muted"></i>
                  <span className="ms-1">{article.author.name}</span>
                </>
              )}{" "}
            </div>
            <div className="d-flex align-items-center fs-6 fw-light pe-2 pt-2">
              <i className="bx bx-calendar fs-5 text-muted"></i>
              <span className="ms-1">
                            {moment(article.updatedAt).format("MMM Do YY")}
                          </span>
            </div>
          </div>

          <div className=" d-flex flex-column mt-3">
            <h2
              className="card-title text-start "
              style={{ maxHeight: 50, overflow: "hidden" }}
            >
              {article.title}
            </h2>
            <div
              className="article-content mt-2 ms-2"
              dangerouslySetInnerHTML={{ __html: article.content }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default StudentArticleView;
