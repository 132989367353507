import React from 'react'

function Loader() {
  return (
   <>
   <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
   </>
  )
}

export default Loader