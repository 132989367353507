import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { PageRoutes } from "../utils/enums";

const menusItems = [
  { title: "Startup Hub", path: PageRoutes.STARTUP_HUB},
  { title: "Legal Reports", path: PageRoutes.LEGAL_REPORT },
  { title: "Curiosity", path:  PageRoutes.CURIOSITY},
  { title: "Your Story", path:  PageRoutes.YOUR_STORY },
  { title: "Student Corner", path: "/student-corner" },
  { title: "Research Paper", path: PageRoutes.RESERACH_PAPER },
  { title: "Jobs/Internships", path:  PageRoutes.JOBS_INTERNSHIP },
  { title: "Get Featured", path: "/get-featured" },
  { title: "About Us", path: "/about" },
 
];
const Header = () => {
  const websiteData = useSelector((state) => state.website);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const location = useLocation(); 
  const handleSearchSubmit = useCallback((e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      // Search query URL mein add karein aur search ke baad input field clear kar dein
      console.log(searchQuery)
      navigate(`?search=${searchQuery}`);
    }
    else {
      setSearchQuery("")
      console.log(searchQuery)
      navigate(`?`);
    }
  },[searchQuery])
  
  useEffect(() => {
    setSearchQuery("");
  }, [location.pathname]); // Run this effect when the pathname changes

  return (
    <>
      {/* <!-- social media--> */}

      <div className="container-fluid bg-light">
        <div className="row">
          <div className="col d-flex align-items-center p-2">
            <div className="text-start">
              <a
                href={websiteData?.instagram ?? "#"}
                className="text-white text-decoration-none"
              >
                <img
                  src="/assets/img/instagram.svg"
                  alt="Download"
                  style={{ width: 20 }}
                />
              </a>

              <a
                href={websiteData?.twitter ?? '#'}
                className="text-white text-decoration-none ms-2"
              >
                <img
                  src="/assets/img/twitter.svg"
                  alt="Download"
                  style={{ width: 20 }}
                />
              </a>

              <a
                href={websiteData?.facebook ?? '#'}
                className="text-white text-decoration-none ms-2"
              >
                <img
                  src="/assets/img/facebook.svg"
                  alt="Download"
                  style={{ width: 20 }}
                />
              </a>

              <a
                href={websiteData?.linkedin ?? '#'}
                className="text-white text-decoration-none ms-2"
              >
                <img
                  src="/assets/img/linkedin.svg"
                  alt="Download"
                  style={{ width: 20 }}
                />
              </a>
            </div>
          </div>
          <div className="col-md-4 px-0">
            <form className="search-container" onSubmit={handleSearchSubmit}>
              <input
                type="text"
                placeholder="Search all websites"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <button type="submit">
                <img src="/assets/search.jfif" alt="Search Icon" />
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* <!-- Responsive navbar--> */}
      <nav className="navbar navbar-expand-lg sticky-top navbar-dark bg-dark ">
        <div className="container-fluid">
          <Link className="navbar-brand" to={"/"}>
            <h1 className="fs-4">The Maximus Report</h1>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end text-bg-dark"
            tabIndex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                The Maximus Report
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav justify-content-end flex-grow-1 pe-1">
                {menusItems.map((item, key) => {
                  return (
                    <li key={key} className="nav-item">
                      <Link
                        className="nav-link"
                        onClick={() => navigate(item.path)}
                        data-bs-dismiss="offcanvas"
                      >
                        {item.title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;