import React, { useState } from "react";
import { toast } from "react-toastify";
import { useForm, useController } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { postResearchPaperApi, razorPayApi } from "../../network/api";
import { useNavigate } from "react-router-dom";
const blankForm = {
  research_paper_title: "",
  area_of_research: "",
  keywords: "",
  research_doc: null,
  author_name: "",
  author_email: "",
  author_contact: "",
  author_linkedin: "",
  author_institution: "",
  co_author: false,
  co_author_name1: "",
  co_author_email1: "",
  co_author_contact1: "",
  co_author_linkedin1: "",
  co_author_institution1: "",
  co_author2: false,
  co_author_name2: "",
  co_author_email2: "",
  co_author_contact2: "",
  co_author_linkedin2: "",
  co_author_institution2: "",
};

const Researchpaper = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm();
  const [terms, setTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [co_author, setCo_author] = useState(false);
  const [co_author2, setCo_author2] = useState(false);
  const  navigate = useNavigate();

  const [formData, setFormData] = useState(blankForm);

  const handleCoAuthorChange1 = (e) => {
    const value = e.target.value;
    setFormData((prev) => ({ ...prev, co_author: value }));
    setCo_author(value === "Yes");
  };

  const handleCoAuthorChange2 = (e) => {
    const value = e.target.value;
    setFormData((prev) => ({ ...prev, co_author2: value }));
    setCo_author2(value === "Yes");
  };

  const handleCheckBox = () => {
    setTerms(!terms);
  };

  const onSubmit = async (data) => {
    console.log(data);
    // if (!terms) {
    //   toast.error("You must accept the terms & conditions.");
    //   return;
    // }

    const formPayload = new FormData();
    Object.keys(data).forEach((key) => {
      formPayload.append(key, data[key]);
    });
    formPayload.delete("research_doc");
    formPayload.append("research_doc", data.research_doc[0]);
    formPayload.append("amount", "100");
    formPayload.append("currency", "INR");

    try {
      setIsLoading(true);
      const response = await postResearchPaperApi(formPayload);

      if (response.status === 200 && response.data.status === 200) {
        const { data } = response.data;
        const id = data._id;
        reset();
        const orderId = response.data.orderId;
        console.log(response);
        console.log(orderId);
        initiateRazorpayPayment(id, orderId);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Submission failed. Please try again.");
    } finally {
      setIsLoading(false);
      setTerms(false);
    }
  };

  const initiateRazorpayPayment = async (id, orderId) => {
    try {
      // Razorpay options setup
      const options = {
        key: "rzp_test_IsocOrs4VaPcZ9",
        amount: "500",
        currency: "INR",
        name: "The Maximus Report",
        description: "Research Paper Submission",
        order_id: orderId,
        handler: async function (response) {
          // try {
          //   // Send the payment details to your server for verification
          //   await razorPayApi(id, {
          //     razorpay_order_id: response.razorpay_order_id,
          //     razorpay_payment_id: response.razorpay_payment_id, // Include payment ID for verification
          //   });

          toast.success("Payment successful!");
          //   navigate(/payment-success/${response.razorpay_payment_id});
          // } catch (error) {
          //   console.error("Error verifying payment:", error);
          //   toast.error("Payment verification failed.");
          // }
        },
        prefill: {
          name: formData.author_name,
          email: formData.author_email,
          contact: formData.author_contact,
        },
        theme: {
          color: "#8a4baf",
        },
      };

      // Open Razorpay modal
      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } catch (error) {
      console.error("Error initiating Razorpay payment:", error);
      if (error.response) {
        console.error("Error response data:", error.response.data);
      }
      toast.error("Failed to initiate payment. Please try again.");
    }
  };

  return (
      <div className="">
        <h2 className="mb-4">Research Paper Submission</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-floating mb-3">
            <input
              type="text"
              className={
                "form-control " +
                (!errors.research_paper_title ? "" : " danger-border")
              }
              {...register("research_paper_title", {
                required: "This field is required",

                pattern: {
                  message: "Invalid value entered ",
                },
              })}
            />
            <p className="error-text">
              <ErrorMessage errors={errors} name="research_paper_title" />
            </p>
            <label htmlFor="research_paper_title">Title <span className="text-danger">*</span></label>
          </div>

          <fieldset className="mb-4">
            <legend>Author Details</legend>
            <div className="form-floating mb-3">
              <input
                type="text"
                className={
                  "form-control" + (!errors.author_name ? "" : " danger-border")
                }
                {...register("author_name", {
                  required: "This field is required",
                  pattern: {
                    message: "Invalid value entered ",
                  },
                })}
              />
              <p className="error-text">
                <ErrorMessage errors={errors} name="author_name" />
              </p>
              <label htmlFor="author_name">Name of Author <span className="text-danger">*</span></label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="email"
                className={
                  "form-control" +
                  (!errors.author_email ? "" : " danger-border")
                }
                {...register("author_email", {
                  required: "This field is required",
                  pattern: {
                    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    message: "Enter valid email address",
                  },
                })}
              />
              <p className="error-text">
                <ErrorMessage errors={errors} name="author_email" />
              </p>
              <label htmlFor="author_email">Email (Author) <span className="text-danger">*</span></label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="text"
                className={
                  "form-control" +
                  (!errors.author_institution ? "" : " danger-border")
                }
                {...register("author_institution", {
                  required: "This field is required",
                  pattern: {
                    message: "Invalid value entered ",
                  },
                })}
              />
              <p className="error-text">
                <ErrorMessage errors={errors} name="author_institution" />
              </p>

              <label htmlFor="author_institution">
                Institutional Affiliation/College/Organization (Author) <span className="text-danger">*</span>
              </label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="url"
                className={
                  "form-control" +
                  (!errors.author_linkedin ? "" : " danger-border")
                }
                {...register("author_linkedin", {
                  // required: "This field is required",
                  // pattern: {
                  //   value:
                  //    /^(https?:\/\/)?(www\.)?linkedin\.com\/.*$/,
                  //   message: "Please enter a valid URL",
                  // },
                })}
              />
              <p className="error-text">
                <ErrorMessage errors={errors} name="author_linkedin" />
              </p>
              <label htmlFor="author_linkedin">LinkedIn (Author) </label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="tel"
                className={
                  "form-control" +
                  (!errors.author_contact ? "" : " danger-border")
                }
                {...register("author_contact", {
                  required: "This field is required",
                  pattern: {
                    value: /^[0-9]{10}$/,
                  message: "Invalid contact number, should be 10 digits"
                  },
                })}
              />
              <p className="error-text">
                <ErrorMessage errors={errors} name="author_contact" />
              </p>
              <label htmlFor="author_contact">Contact Number (Author) <span className="text-danger">*</span></label>
            </div>
          </fieldset>

          <fieldset className="mb-3">
            <legend className="form-label">Co-Author 1</legend>
            <div className="d-flex gap-4">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="co_author_1"
                  value="Yes"
                  onChange={handleCoAuthorChange1}
                />
                <label className="form-check-label" htmlFor="co_author_1_yes">
                  Yes
                </label>
              </div>

              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="co_author_1"
                  value="No"
                  defaultChecked
                  onChange={handleCoAuthorChange1}
                />
                <label className="form-check-label" htmlFor="co_author_1_no">
                  No
                </label>
              </div>
            </div>
          </fieldset>

          {co_author && (
            <fieldset className="mb-4">
              <legend>Co-Author 1 Details</legend>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className={
                    "form-control" +
                    (!errors.co_author_name1 ? "" : " danger-border")
                  }
                  {...register("co_author_name1", {
                    required: "This field is required",
                    pattern: {
                      message: "Invalid value entered ",
                    },
                  })}
                />
                <p className="error-text">
                  <ErrorMessage errors={errors} name="co_author_name1" />
                </p>
                <label htmlFor="co_author_name1">Name of Co-Author <span className="text-danger">*</span></label>
              </div>

              <div className="form-floating mb-3">
                <input
                  type="email"
                  className={
                    "form-control" +
                    (!errors.co_author_email1 ? "" : " danger-border")
                  }
                  {...register("co_author_email1", {
                    required: "This field is required",
                    pattern: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: "Invalid value entered ",
                    },
                  })}
                />
                <p className="error-text">
                  <ErrorMessage errors={errors} name="co_author_email1" />
                </p>
                <label htmlFor="co_author_email1" aria-required>
                  Email (Co-Author) <span className="text-danger">*</span>
                </label>
              </div>

              <div className="form-floating mb-3">
                <input
                  type="text"
                  className={
                    "form-control" +
                    (!errors.co_author_institution1 ? "" : " danger-border")
                  }
                  {...register("co_author_institution1", {
                    required: "This field is required",
                    pattern: {
                      message: "Invalid value entered ",
                    },
                  })}
                />
                <p className="error-text">
                  <ErrorMessage errors={errors} name="co_author_institution1" />
                </p>
                <label htmlFor="co_author_institution1" aria-required>
                  Institutional Affiliation/College/Organization (Co-Author) <span className="text-danger">*</span>
                </label>
              </div>

              <div className="form-floating mb-3">
                <input
                  type="url"
                  className={
                    "form-control" +
                    (!errors.co_author_linkedin1 ? "" : " danger-border")
                  }
                  {...register("co_author_linkedin1", {
                    // required: "This field is required",
                    // pattern: {
                    //   value:
                    //  /^(https?:\/\/)?(www\.)?linkedin\.com\/.*$/,
                    // message: "Please enter a valid URL",
                    // },
                  })}
                />
                <p className="error-text">
                  <ErrorMessage errors={errors} name="co_author_linkedin1" />
                </p>
                <label htmlFor="co_author_linkedin1">LinkedIn (Co-Author) </label>
              </div>

              <div className="form-floating mb-3">
                <input
                  type="tel"
                  className={
                    "form-control" +
                    (!errors.co_author_contact1 ? "" : " danger-border")
                  }
                  {...register("co_author_contact1", {
                    required: "This field is required",
                    pattern: {
                      value: /^[0-9]{10}$/,
                  message: "Invalid contact number, should be 10 digits"
                    },
                  })}
                />
                <p className="error-text">
                  <ErrorMessage errors={errors} name="co_author_contact1" />
                </p>
                <label htmlFor="co_author_contact1" aria-required>
                  Contact Number (Co-Author) <span className="text-danger">*</span>
                </label>
              </div>
            </fieldset>
          )}

          <fieldset className="mb-3">
            <legend className="form-label">Co-Author 2</legend>
            <div className="d-flex gap-4">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="co_author_2"
                  value="Yes"
                  onChange={handleCoAuthorChange2}
                />
                <label className="form-check-label" htmlFor="co_author_2_yes">
                  Yes
                </label>
              </div>

              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="co_author_2"
                  value="No"
                  defaultChecked
                  onChange={handleCoAuthorChange2}
                />
                <label className="form-check-label" htmlFor="co_author_2_no">
                  No
                </label>
              </div>
            </div>
          </fieldset>

          {co_author2 && (
            <fieldset className="mb-4">
              <legend>Co-Author2 Details</legend>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className={
                    "form-control" +
                    (!errors.co_author_name2 ? "" : " danger-border")
                  }
                  {...register("co_author_name2", {
                    required: "This field is required",
                    pattern: {
                      message: "Invalid value entered ",
                    },
                  })}
                />
                <p className="error-text">
                  <ErrorMessage errors={errors} name="co_author_name2" />
                </p>
                <label htmlFor="co_author2_name">Name of Co-Author2 <span className="text-danger">*</span></label>
              </div>

              <div className="form-floating mb-3">
                <input
                  type="email"
                  className={
                    "form-control" +
                    (!errors.co_author_email2 ? "" : " danger-border")
                  }
                  {...register("co_author_email2", {
                    required: "This field is required",
                    pattern: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: "Invalid value entered ",
                    },
                  })}
                />
                <p className="error-text">
                  <ErrorMessage errors={errors} name="co_author_email2" />
                </p>
                <label htmlFor="co_author2_email" aria-required>
                  Email (Co-Author2) <span className="text-danger">*</span>
                </label>
              </div>

              <div className="form-floating mb-3">
                <input
                  type="text"
                  className={
                    "form-control" +
                    (!errors.co_author_institution2 ? "" : " danger-border")
                  }
                  {...register("co_author_institution2", {
                    required: "This field is required",
                    pattern: {
                      message: "Invalid value entered ",
                    },
                  })}
                />
                <p className="error-text">
                  <ErrorMessage errors={errors} name="co_author_institution2" />
                </p>
                <label htmlFor="co_author2_institution" aria-required>
                  Institutional Affiliation/College/Organization (Co-Author2) <span className="text-danger">*</span>
                </label>
              </div>

              <div className="form-floating mb-3">
                <input
                  type="url"
                  className={
                    "form-control" +
                    (!errors.co_author_linkedin2 ? "" : " danger-border")
                  }
                  {...register("co_author_linkedin2", {
                    // required: "This field is required",
                    // pattern: {
                    //   value:
                    //   /^(https?:\/\/)?(www\.)?linkedin\.com\/.*$/,
                    //  message: "Please enter a valid URL",
                    // },
                  })}
                />
                <p className="error-text">
                  <ErrorMessage errors={errors} name="co_author_linkedin2" />
                </p>
                <label htmlFor="co_author2_linkedin">
                  LinkedIn (Co-Author2) 
                </label>
              </div>

              <div className="form-floating mb-3">
                <input
                  type="tel"
                  className={
                    "form-control" +
                    (!errors.co_author_contact2 ? "" : " danger-border")
                  }
                  {...register("co_author_contact2", {
                    required: "This field is required",
                    pattern: {
                      value: /^[0-9]{10}$/,
                  message: "Invalid contact number, should be 10 digits"
                    },
                  })}
                />
                <p className="error-text">
                  <ErrorMessage errors={errors} name="co_author_contact2" />
                </p>
                <label htmlFor="co_author2_contact" aria-required>
                  Contact Number (Co-Author2) <span className="text-danger">*</span>
                </label>
              </div>
            </fieldset>
          )}

          <fieldset className="mb-3">
            <legend>Research Information</legend>
            <div className="form-floating mb-3">
              <input
                type="text"
                className={
                  "form-control" +
                  (!errors.area_of_research ? "" : " danger-border")
                }
                {...register("area_of_research", {
                  required: "This field is required",
                  pattern: {
                    message: "Invalid value entered ",
                  },
                })}
              />
              <p className="error-text">
                <ErrorMessage errors={errors} name="area_of_research" />
              </p>
              <label htmlFor="area_of_research">Area of Research <span className="text-danger">*</span></label>
            </div>

            <div className="form-floating mb-3">
              <input
                type="text"
                className={
                  "form-control" + (!errors.keywords ? "" : " danger-border")
                }
                {...register("keywords", {
                  required: "This field is required",
                  pattern: {
                    message: "Invalid value entered ",
                  },
                })}
              />
              <p className="error-text">
                <ErrorMessage errors={errors} name="keywords" />
              </p>
              <label htmlFor="keywords">Keywords <span className="text-danger">*</span></label>
            </div>

            <div className="mb-3">
              <label htmlFor="research_doc" className="form-label">
                Upload Manuscript (doc./docx.) (Max. 10 MB) <span className="text-danger">*</span>
              </label>
              <div className="input-group">
                <input
                  type="file"
                  className={
                    "form-control w-100" +
                    (!errors.research_doc ? "" : " danger-border")
                  }
                  // accept=".doc,.docx" // Limit accepted file types
                  {...register("research_doc", {
                    required: "This field is required",
                    validate: {
                      fileType: (value) =>
                        value[0]?.type === "application/msword" ||
                        value[0]?.type ===
                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                        "Only .doc or .docx files are allowed",
                    },
                  })}
                />
                <p className="error-text">
                  <ErrorMessage errors={errors} name="research_doc" />
                </p>
              </div>
            </div>
          </fieldset>

          <div className="container">
            <h3 className="mt-4 text-start">Terms & Conditions </h3>

            <h5 className="mt-4">"The Maximus Report" reserves the right to:</h5>

            <p className="mt-3 "style={{ textAlign: "justify" }}>
              The content is original and does not contain any plagiarized
              material. All references, data, and citations in the Paper are
              accurate and properly attributed. The Paper does not contain any
              defamatory, abusive, or otherwise unlawful content. "The Maximus Report" may
              terminate or suspend your ability to submit or access your Paper
              at any time, with or without cause, and without prior notice. "The Maximus Report"
              reserves the right to update these Terms and Conditions at any
              time. The latest version will be posted on our platform. By
              continuing to submit or have your Paper hosted on our platform,
              you accept the updated Terms. By submitting your research paper to
              "The Maximus Report", you acknowledge that you have read, understood, and agree to
              these Terms and Conditions.
            </p>

            <div className="form-check mt-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="termsCheckbox"
                onClick={handleCheckBox}
              />
              <label className="form-check-label" htmlFor="termsCheckbox">
                I accept all terms & conditions.
              </label>
            </div>

            <div className="d-flex gap-4 mt-3 mb-5">
              {isLoading ? (
                <div className="spinner-border" role="status">
                  <span className="sr-only"></span>
                </div>
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary w-100 mb-3"
                  disabled={!terms}
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
  );
};

export default Researchpaper;