import React, { useEffect } from "react";

const Pagination = ({ total, page, setPage }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  return (
    <div>
      <div className="d-flex justify-content-center mt-4 mb-3">
        <button
          className="btn btn-primary me-2"
          disabled={page === 1}
          onClick={() => setPage(page - 1)}
        >
          Previous
        </button>
        {Array.from({ length: total }, (_, i) => (
          <button
            key={i}
            className={`btn ${
              i + 1 === page ? "btn-secondary" : "btn-light"
            } mx-1`}
            onClick={() => setPage(i + 1)}
          >
            {i + 1}
          </button>
        ))}
        <button
          className="btn btn-primary ms-2"
          disabled={page === total}
          onClick={() => setPage(page + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Pagination;
