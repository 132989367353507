import React, { useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { toast } from "react-toastify";
import { getActiveAdsApi } from "../network/api";

const Modal = () => {
  const [data, setData] = useState(null);
  const [modalShown, setModalShown] = useState(false);

  const fetchAds = async () => {
    try {
      let response = await getActiveAdsApi();
      if (response.status === 200 || response.data.status === 200) {
        let ads = response.data.data;
        // Check if the response is an array of ads or a single ad object
        if (Array.isArray(ads)) {
          if (ads.length > 0) {
            // Pick a random ad from the array
            const randomAd = ads[Math.floor(Math.random() * ads.length)];
            setData(randomAd);
          } else {
            setData(null); // No active ads
          }
        } else if (ads && typeof ads === "object") {
          setData(ads); // Single ad object
        } else {
          setData(null); // Handle edge cases where data is neither an array nor an object
        }
      } else {
        setData(null);
      }
    } catch (e) {
      console.log("Fetch Error:", e);
      toast.error(e.message);
    }
  };

  useEffect(() => {
    fetchAds();
  }, []);

  const openModal = useCallback(() => {
    if (data && data.image && !modalShown) {
      const modalElement = document.getElementById("staticBackdrop");
      const modal = new window.bootstrap.Modal(modalElement);
      setTimeout(() => {
        modal.show();
        setModalShown(true);
      }, 5000); 
    }
  }, [data, modalShown]);

  useEffect(() => {
    if (data) {
      openModal();
    }
  }, [data, openModal]);

  return data
    ? ReactDOM.createPortal(
        <>
          <div
            className="modal fade"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <img
                    src={data.image}
                    alt="Advertisement"
                    className="img-fluid"
                    style={{
                      borderRadius: "10px",
                      display: "block",
                      margin: "0 auto", // Center the image
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{ __html: data.text }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </>,
        document.querySelector("#portalModal")
      )
    : null;
};

export default Modal;
