import React from 'react';

function SubmissionGuidelines() {
  return (
    <>
     <div className="p-lg-5 p-3 text-justified">
      <h2 className='mb-5'>Submission Guidelines</h2>
      
      <h3 className='mb-3'>I. General Instructions:</h3>
      <ul>
        <li>Long articles (3,000-5,000 words) must include an abstract of at least 300 words, while short articles (1,500-3,000 words) require an abstract of at least 150 words.</li>
        <li className='mt-2'>Each submission may include one primary author and up to two co-authors.</li>
        <li className='mt-2'>All submitted works must be original, unpublished, and must not be under review by any other journal.</li>
        <li className='mt-2'>Any instance of plagiarism will lead to immediate disqualification from publication.</li>
        <li className='mt-2'>Authors will bear sole responsibility for any disputes arising from their manuscripts, including but not limited to issues related to copyright infringement, defamation, objectionable content, or contempt. Authors agree to accept any resulting liabilities from copyright violations or other infringements.</li>
        <li className='mt-2'>Although adherence to the specified word limits for each submission category is encouraged, the journal may exercise discretion regarding word count, based on the article’s quality.</li>
        <li className='mt-2'>By granting The Maximus Report the non-exclusive right to publish and distribute the paper in any format, authors consent to potential conversion of the paper to an article format for publication on the website’s “Take a Look” section, with proper credit attributed to the author and co-authors (if applicable). Upon submission, the manuscript shall be the property of The Maximus Report. Henceforth, the articles accepted for publication may be cross-published/republished. Such cross-publishing/republishing shall not require the prior consent of the author(s).</li>
      </ul>
      
      <h3>II. Format:</h3>
      <ul>
        <li className='mt-2'>The first page of the manuscript must include the paper’s title, the authors' names, and their institutional affiliations, followed by an abstract.</li>
        <li className='mt-2'>The second page should begin with the main content of the manuscript.</li>
        <li className='mt-2'>The main text should be formatted in Times New Roman, font size 12, with 1.5 line spacing, while footnotes should be in Times New Roman, font size 10, with 1.0 line spacing. All headings should follow a consistent format throughout the manuscript.</li>
        <li className='mt-2'>Both text and footnotes should adhere to a standard citation style, such as the Bluebook 20th Edition, APA, MLA, or ILI Footnoting Style.</li>
      </ul>
      
      <p>⁕ In case of any difficulty in filling the submission form, the authors can submit their manuscript via email <a href="mailto:contact@themaximusreport.com">contact@themaximusreport.com</a> with a declaration to the following effect:</p>
      
      <blockquote>
        By submitting this research paper to The Maximus Report, I affirm that the work is original, does not infringe upon any existing copyrights, and has not been previously published elsewhere. I take full responsibility for the accuracy of the content as well as for any disputes arising from the manuscript, including those related to copyright, defamation, objectionable content, or contempt, and agree to bear any losses incurred due to the violation of copyright or other rights. I understand that The Maximus Report assumes no liability for any claims, errors, or inaccuracies in the submitted work. I grant The Maximus Report the non-exclusive right to publish and distribute this paper in any format, while retaining all other rights to my work.
      </blockquote>
      </div>
    </>
  );
}

export default SubmissionGuidelines;
