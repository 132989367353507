import React from "react";
import { Link } from "react-router-dom";

function ResearchPaperCard({ data }) {
  return (
    <div className="container mt-4">
      <div
        className="card shadow-sm p-4 rounded"
        style={{
          cursor: "pointer",
          border: "1px solid #ddd",
          backgroundColor: "#ffffff",
          transition: "transform 0.2s, box-shadow 0.2s",
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Link
          to={`/research-paper/${data._id}`}
          style={{ textDecoration: "underline" }}
        >
          <h4
            style={{
              fontWeight: "600",
              color: "rgb(0 118 255)",
              cursor: "pointer",
            }}
            onMouseEnter={(e) => (e.target.style.color = "#0054ce")}
            onMouseLeave={(e) => (e.target.style.color = "rgb(0 118 255)")}
          >
            {data.research_paper_title}
          </h4>
        </Link>

        <div
          className="d-flex align-items-center mb-2"
          style={{ color: "#666" }}
        >
          <i className="bx bxs-user-circle me-2"></i>
          <span>{data.author_name}</span>
        </div>

        {data.area_of_research && (
          <p style={{ color: "#888", fontSize: "0.9rem" }}>
            {data.area_of_research}
          </p>
        )}
      </div>
    </div>
  );
}

export default ResearchPaperCard;
