import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getWebsiteDataApi } from "../../network/api"
import { toast } from "react-toastify";
import { HASHTAG } from "../../utils/enums";

const initialState = {
    phone:'',
    instagram:'',
    facebook:'',
    twitter:'',
    carousel:[],
    testimonials:[],
    policy:'',
    terms:'',
    activeHashtag:'',
    contactEmail:''
}

//Action
export const getWebSiteData = createAsyncThunk('website/getData', async (_, thunkApi) => {
    try {
      let response = await getWebsiteDataApi();
      if(response.status == 200 && response.data.status == 200){
        return response.data
      }else{
        return thunkApi.rejectWithValue(response.data.message ?? response.statusText);
      }
    } catch (e) {
      console.log(e);
      return thunkApi.rejectWithValue(e.response?.data?.message ?? e.message);
    }
  })


const websiteSlice = createSlice({
    name:'websiteData',
    initialState:initialState,
    reducers:{
        setActiveHashtag: (state,action) => {
          state.activeHashtag = action.payload;
        }
    },
    extraReducers:(builder)=>{
        //reducers
        builder.addCase(getWebSiteData.fulfilled,(state,action)=>{
          console.log("Website data received:", action.payload);
            // state.phone = action.payload.data.social.phone
            state.facebook = action.payload.data.social.facebook;
            state.instagram = action.payload.data.social.instagram;
            state.twitter = action.payload.data.social.twitter;
            state.linkedin = action.payload.data.social.linkedin;
            state.carousel = action.payload.data.carousel ?? []
            state.testimonials = action.payload.data.testimonials ?? []
            state.contactEmail=action.payload.data.pages.contactEmail;
            //toast.success("Website loaded successfully");
            console.log("Updated contactEmail:", state.contactEmail);

        }).addCase(getWebSiteData.rejected, (state,action)=>{
            toast.error(action.payload);
        })
    }
})
export const {setActiveHashtag} =  websiteSlice.actions
export const websiteReducer = websiteSlice.reducer
