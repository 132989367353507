import { useSelector } from "react-redux";
import StudentLoginPage from "./student-corner/StudentLoginPage";
import { Route, Routes, Navigate } from "react-router-dom";
import StudentRegisterPage from "./student-corner/StudentRegisterPage";
import StudentHomeContainer from "./student-corner/StudentHomeContainer";
import ResetPasswordPage from "./student-corner/ResetPasswordPage";

const StudentCornerPage = () => {
  const auth = useSelector((state) => state.auth);
  return (
    <>
      {auth.token == null ? (
        <Routes>
          <Route path="/" element={<StudentLoginPage />} />
          <Route path="/register" element={<StudentRegisterPage />} />
          <Route path="/forgot-password" element={<ResetPasswordPage />} />
          <Route path="/*" element={<Navigate to={'/student-corner'}/>} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/*" element={<StudentHomeContainer />} />
        </Routes>
      )}
    </>
  );
};

export default StudentCornerPage;
