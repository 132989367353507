import React from 'react';

function ReviewProcess() {
  return (
    <div className="p-lg-5 p-3 text-justified">
      <h2 className='mb-4'>Review Process</h2>
      <p>
        The Maximus Report employs a Blind Peer Review process, ensuring that the identities of both reviewers and authors remain confidential from one another throughout the review.
      </p>
      <p>
        Additional details concerning the submission and review procedures are available in linked sections. Authors are encouraged to review these guidelines thoroughly before submitting any manuscript.
      </p>
    </div>
  );
}

export default ReviewProcess;
