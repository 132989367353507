import { useState } from "react";
import { useDispatch } from "react-redux";
import { registerStudent } from "../../redux/slices/authSlice";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form"; 
import { toast } from 'react-toastify'; 

const StudentRegisterPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false); 

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(); 

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data) => {
    setIsLoading(true); 
    console.log("Submitted Data:", data); 
    try {
     let res = await dispatch(registerStudent(data)).unwrap(); 
      if(res){
        console.log("result",res);
        navigate("/student-corner"); 
      }
      
    } catch (error) {
      console.error("Registration Error:", error);
    } finally {
      setIsLoading(false); 
    }
  };

  return (
    <>
      <div className="container-fluid border rounded">
        <h1 className="h3 mb-4 mt-3 fw-normal text-center">Student Register</h1>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-floating mb-3">
            <input
              type="text" 
              className={`form-control ${errors.name ? 'is-invalid' : ''}`} 
              id="name"
              placeholder="Enter your name"
              {...register("name", { required: "Name is required" })} 
            />
            <label htmlFor="name">Name</label>
            {errors.name && <div className="error-text">{errors.name.message}</div>} 
          </div>

          <div className="form-floating mb-3">
            <input
              type="email"
              className={`form-control ${errors.email ? 'is-invalid' : ''}`} 
              id="email"
              placeholder="name@example.com"
              {...register("email", { 
                required: "Email is required", 
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "Invalid email address"
                }
              })} 
            />
            <label htmlFor="email">Email address</label>
            {errors.email && <div className="error-text">{errors.email.message}</div>} 
          </div>

          <div className="form-floating mb-3">
            <input
              type="date"
              className={`form-control ${errors.DOB ? 'is-invalid' : ''}`} 
              id="DOB"
              placeholder="Date of Birth"
              {...register("DOB", { required: "Date of Birth is required" })} 
            />
            <label htmlFor="DOB">Date of Birth</label>
            {errors.DOB && <div className="error-text">{errors.DOB.message}</div>}
          </div>

          <div className="form-floating mb-3">
            <input
              type="tel"
              className={`form-control ${errors.contact ? 'is-invalid' : ''}`} 
              id="contact"
              placeholder="Contact Number"
              {...register("contact", { 
                required: "Contact number is required",
                pattern: {
                  value: /^[0-9]{10}$/,
                  message: "Invalid contact number, should be 10 digits"
                }
              })} 
            />
            <label htmlFor="contact">Contact Number</label>
            {errors.contact && <div className="error-text">{errors.contact.message}</div>}
          </div>

          <div className="form-floating mb-3 position-relative">
            <input
              type={showPassword ? "text" : "password"}
              className={`form-control ${errors.password ? 'is-invalid' : ''}`} 
              id="password"
              placeholder="Password"
              {...register("password", { required: "Password is required" })} 
            />
            <label htmlFor="password">Password</label>
            <button
              type="button"
              className="btn btn-outline-secondary position-absolute end-0 top-50 translate-middle-y"
              onClick={togglePasswordVisibility}
              style={{ zIndex: 2, marginRight: "30px" }}
            >
              <i className={`bx ${showPassword ? "bx-hide" : "bx-show"}`}></i>
            </button>
          </div>
          {errors.password && <div className="error-text">{errors.password.message}</div>}

          <button className="btn btn-primary w-100 py-2 mb-2" type="submit" disabled={isLoading}>
            {isLoading ? 'Registering...' : 'Register'}
          </button>
          <div className="d-flex justify-content-between mb-3">
            <Link to={'/student-corner'}>Login</Link>
            <Link to={'/student-corner/forgot-password'}>Forgot Password?</Link>
          </div>
        </form>
      </div>
    </>
  );
};

export default StudentRegisterPage;
