import React, { useEffect, useState } from "react";
import ResearchPaperCard from "../components/ReseachPaperCard";
import { toast } from "react-toastify";
import { HASHTAG } from "../utils/enums";
import { getApprovedResearchOnWebApi } from "../network/api";
import Loader from "../components/Loader";
import Pagination from "../components/Pagination";
import { useLocation } from "react-router-dom";

function ShowAllResearchPaper() {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const location = useLocation();

  // Extract search query from URL parameters
  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return params.get("search") || "";
  };

  const fetchResearchPaperDetails = async () => {
    try {
      setLoading(true);
      const title = getQueryParams(); // Get search query from URL
      const response = await getApprovedResearchOnWebApi(page, title); // Pass searchQuery to API
      if (response.status === 200 || response.data.status === 200) {
        setArticles(response.data.data);
        setTotal(response.data.total);
      }
    } catch (e) {
      console.error("Fetch Error:", e);
      toast.error("Failed to fetch research papers.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchResearchPaperDetails();
  }, [page, location.search]); // Trigger fetch on URL search change

  return (
    <>
      <div className="container mt-3 mb-3">
        <h2 className="ms-3">Research Papers</h2>

        {loading ? (
          <div className="text-center">
            <Loader />
          </div>
        ) : articles.length > 0 ? (
          <div className="row">
            {articles.map((data, index) => (
              <div className="col-12" key={index}>
                <ResearchPaperCard data={data} />
              </div>
            ))}
          </div>
        ) : (
          <div className="col-12 d-flex align-items-center justify-content-center" style={{ minHeight: '200px' }}>
          <p className="text-center"><i>No research papers found.</i></p>
        </div>
        )}

        {articles.length > 0 && (
          <Pagination total={total} page={page} setPage={setPage} />
        )}
      </div>
    </>
  );
}

export default ShowAllResearchPaper;
