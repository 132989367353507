import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getAllIndexingByUserApi } from "../network/api";

function IndexingPartners() {
  const [indexingPartners, setIndexingPartners] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchIndexingPartners = async () => {
    setLoading(true);
    try {
      let response = await getAllIndexingByUserApi();
      if (response.status === 200 || response.data.status === 200) {
        const fetchedData = response.data.data;
        setIndexingPartners(fetchedData);
      }
    } catch (error) {
      console.log("Fetch Error:", error);
      toast.error("Failed to load indexing partners.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchIndexingPartners();
  }, []);

  return (
    <>
      <div className="card">
        <div className="card-title text-center mt-3">Indexing Partners</div>
        <div className="card-body">
          {loading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh" }}>
              <div className="spinner-border text-dark" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : indexingPartners.length === 0 ? (
            <p className="text-center">No indexing partners available.</p>
          ) : (
            <div className="d-flex flex-column align-items-center">
              {indexingPartners.map((partner, index) => (
                <div className="mb-4" key={index}>
                  <img
                    src={partner.image}
                    alt={`Indexing Partner ${index + 1}`}
                    className="img-fluid"
                    style={{ maxWidth: "100%" }}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default IndexingPartners;
